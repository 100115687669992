import { Page } from '../enums/Page'
import { NavItem } from '../types/Layout'
import { QuestionTypeSettings, QuestionTypesType } from '../types/QuestionType'

export const sideNavs: NavItem[] = [
    {
        id: 'home',
        icon: '/asset/sidebar-menu-link-gray.svg',
        activeIcon: '/asset/sidebar-menu-link.svg',
        title: 'Campaigns',
        uri: Page.HOME,
    },
    {
        id: 'brand',
        icon: '/asset/sidebar-menu-brush-gray.svg',
        activeIcon: '/asset/sidebar-menu-brush.svg',
        title: 'Brand',
        uri: Page.PROFILES,
    },
    {
        id: 'users',
        icon: '/asset/users-gray.svg',
        activeIcon: '/asset/users_inactive.svg',
        title: 'User management',
        uri: Page.USERS,
    },
    // {
    //     id: 'settings',
    //     icon: '/asset/right-menu-gear-icon.svg',
    //     title: 'Settings',
    //     uri: Page.SETTINGS,
    // },
]

export const collectNames = [
    {
        value: 'No',
        label: 'Don’t collect',
    },
    {
        value: 'optional',
        label: 'Optional',
    },
    {
        value: 'required',
        label: 'Required',
    },
]

export const getPro = [
    {
        value: 'afterReponse',
        label: 'Customize message after responding',
    },
    {
        value: 'addIdenitifier',
        label: 'Add identifiers (name, email, etc.)',
    },
    {
        value: 'disable',
        label: 'Disable Nodeio branding',
    },
    {
        value: 'addYNquestions',
        label: 'Add Y/N questions',
    },
]

export const questionTypes: QuestionTypesType[] = [
    {
        id: QuestionTypeSettings.VOICE_ONLY,
        icon: '/asset/microphone.svg',
        title: 'Voice Only',
        tooltip:
            'Use this one for open-ended questions and/or asking about memories or experiences.',
        color: '#007AFF',
    },
    {
        id: QuestionTypeSettings.VOICE_OR_TEXT,
        icon: '/asset/mic-msg.svg',
        title: 'Voice/Text',
        tooltip: 'Use this one to give customers the option to record or type their answer.',
        color: 'var(--primary-500, #9E77ED)',
    },
    // {
    //     id: QuestionTypeSettings.FREE_TEXT,
    //     icon: '/asset/msg.svg',
    //     title: 'Free Text',
    //     tooltip: 'Use this one for open-ended questions and/or asking about memories or experiences.',
    //     color: "var(--green-light-600, #4CA30D)"
    // },
    {
        id: QuestionTypeSettings.SINGLE_SELECT,
        icon: '/asset/checkbox.svg',
        title: 'Single Select',
        tooltip:
            'A Single Select question lets you present a set of fixed answers. Request customers to pick either one of the options from the provided list.',
        color: 'var(--yellow-500, #EAAA08)',
    },
    {
        id: QuestionTypeSettings.MULTI_SELECT,
        icon: '/asset/check-circle.svg',
        title: 'Multiple Select',
        tooltip:
            'A Multiple Select allows you to create a closed-ended question with multiple answer choices. Ask your customers to select a one or many choices from a list of options.',
        color: 'var(--warning-600, #DC6803)',
    },
    // {
    //     id: QuestionTypeSettings.NO_RESPONSE,
    //     icon: '/asset/message-x.svg',
    //     title: 'No Response',
    //     tooltip: 'Use this one for open-ended questions and/or asking about memories or experiences.',
    //     color: 'var(--pink-600, #DD2590)'
    // },
]

export const defaultBrandBgColor = '#E4F1FF'
