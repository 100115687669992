import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

const Profiles = (): JSX.Element => {
    const navigate = useNavigate()
    const {
        state: { user },
    } = useUserStore()

    useEffect(() => {
        navigate(Page.PROFILE.replace(':id', user.username))
    }, [])
    return (
        <div className="flex-1 px-[40px] sm:px-[70px] lg:pr-[120px]">
            <div>
                <h2 className="text-[25px] font-bold text-[#BFDEFF]">All Profiles</h2>
            </div>
        </div>
    )
}

export default Profiles
