import { AuthenticationApi } from '../api/authentication'
import PhoneNumberInput from '../components/PhoneNumberInput'
import { useUserStore } from '../context/user/store'
import { User } from '../types/User'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const Users = (): JSX.Element => {
    const {
        state: { user },
    } = useUserStore()

    const [users, setUsers] = useState<User[] | null>([])
    const [inviteModal, setInviteModal] = useState<boolean>(false)
    const [removeModal, setRemoveModal] = useState<boolean>(false)
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = useState<string>('')
    const [phoneNumberRefresh, setPhoneNumberRefresh] = useState<boolean>(false)

    const getSharedUsers = async () => {
        const response = await AuthenticationApi.getSharedUsers()
        if (response.status === 200) {
            setUsers(response.data)
        } else {
            setUsers(null)
        }
    }

    useEffect(() => {
        getSharedUsers()
    }, [])

    const toggleInviteModal = (b: boolean) => {
        setPhoneNumber('')
        setPhoneNumberError('')
        setPhoneNumberRefresh(b)
        setInviteModal(b)
    }
    const toggleRemoveModal = () => setRemoveModal(!removeModal)

    const inviteUser = async () => {
        if (!phoneNumber) {
            setPhoneNumberError('Required.')
            return
        }

        try {
            const response = await AuthenticationApi.shareProfileInvite(phoneNumber)

            if (response.status === 200) {
                toggleInviteModal(false)
                toast.success(
                    <p className="text-[#027A48] text-sm font-semibold">
                        Invite sent successfully!
                    </p>,
                    {
                        icon: <img src="/asset/green_check.svg" />,
                    },
                )
                await getSharedUsers()
            } else {
                toast.error(<p className="text-sm font-semibold">Invite failed.</p>)
            }
        } catch (err: any) {
            if (err?.response?.data?.message) {
                setPhoneNumberError(err?.response?.data?.message)
            }
        }
    }

    const removeProfile = async () => {
        try {
            const response = await AuthenticationApi.deleteUser(selectedUserId)

            if (response.status === 200) {
                toggleRemoveModal()
                getSharedUsers()
                toast.success(
                    <p className="text-[#027A48] text-sm font-semibold">
                        User removed successfully!
                    </p>,
                    {
                        icon: <img src="/asset/green_check.svg" />,
                    },
                )
            } else {
                toast.error(<p className="text-sm font-semibold">Removing failed.</p>)
            }
        } catch (err: any) {
            if (err?.response?.data?.message) {
                toast.error(<p className="text-sm font-semibold">{err?.response?.data?.message}</p>)
            }
        }
    }

    return (
        <div className="flex-1 px-4 sm:px-[40px] lg:pr-[120px]">
            <div className="flex justify-between items-center">
                <div>
                    <h2 className="text-[25px] font-bold text-[#8092A5]">User management</h2>
                    <p className="text-base text-black mt-6">Manage your users</p>
                </div>
                <button
                    className="flex justify-center items-center bg-[#007AFF] rounded-full text-sm text-white p-2.5 px-4"
                    onClick={() => {
                        toggleInviteModal(true)
                    }}
                >
                    Add a user
                </button>
            </div>
            <div className="w-full h-[1px] bg-[#EAECF0] my-4 mb-5"></div>
            <div className="relative overflow-x-auto rounded-xl border-[var(--gray-400,_#98A2B3)] border">
                <table className="w-full text-sm text-left text-gray-400">
                    <thead className="text-xs bg-[#F7F7F7] text-black border-b-[var(--gray-400,_#98A2B3)] border-b">
                        <tr>
                            <th scope="col" className="px-6 py-3 font-medium w-[60%] min-w-[170px]">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3 font-medium min-w-[170px]">
                                Phone number
                            </th>
                            <th scope="col" className="px-6 py-3 font-medium min-w-[140px]">
                                Date added
                            </th>
                            <th scope="col" className="px-3 w-[70px] min-w-[70px]"></th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {users &&
                            users?.length > 0 &&
                            users?.map((u: User) => (
                                <tr
                                    key={u?.id}
                                    className="bg-[#F7F7F7] border-b-[var(--gray-400,_#98A2B3)] border-b cursor-default even:bg-[#C6C6C6]] hover:bg-[#579be48e]"
                                >
                                    <th
                                        scope="row"
                                        className="px-6 py-4 font-medium whitespace-nowrap text-black flex items-center"
                                    >
                                        <img
                                            src={
                                                u?.profile_photo?.url ??
                                                'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                            }
                                            className="w-[35px] h-[35px] object-cover rounded-full"
                                        />
                                        <span className="ml-2">
                                            {[u?.first_name, u?.last_name].join(' ')}
                                        </span>
                                    </th>
                                    <td className="px-6 py-4 text-black">{u?.phone_number}</td>
                                    <td className="px-6 py-4 text-black">
                                        {moment(u?.created_at).format('YYYY-MM-DD')}
                                    </td>
                                    <td className="px-6 py-4 text-black">
                                        <button
                                            onClick={() => {
                                                if (u?.id === user.id) return
                                                setSelectedUserId(u?.id)
                                                toggleRemoveModal()
                                            }}
                                        >
                                            <img
                                                src="/asset/red_trash_bin.svg"
                                                className={`${
                                                    u?.id === user.id
                                                        ? 'opacity-20 cursor-default'
                                                        : 'opacity-100'
                                                }`}
                                            />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        {users == null && (
                            <tr className="bg-[#344054] h-[500px]">
                                <td colSpan={4} className="text-center">
                                    <p className="text-xl text-black">No added users</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div
                className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                    inviteModal ? 'fixed' : 'hidden'
                }`}
            >
                <div
                    className="relative w-[300px] sm:w-[400px] bg-white p-[20px] py-[20px] rounded-2xl flex flex-col z-[70]"
                    style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                >
                    <div className="flex-1">
                        <h2 className="text-[#101828] text-[20px] font-semibold leading-10 pb-2">
                            Invite a new user
                        </h2>
                        <p className="text-sm text-[#344054] font-medium py-2 pb-4">Phone number</p>
                        <PhoneNumberInput
                            onChange={(phone) => {
                                setPhoneNumberError('')
                                setPhoneNumber(phone)
                            }}
                            refresh={phoneNumberRefresh}
                            direction="col"
                        />
                        {phoneNumberError && (
                            <p className="text-sm text-[#F04438] ml-0.5 mt-1">{phoneNumberError}</p>
                        )}
                    </div>

                    <div className="flex flex-col gap-3 mt-5">
                        <button
                            className="text-white text-base font-semibold bg-[#007AFF] p-2 px-5 rounded-xl text-center shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border-[#007AFF] border"
                            onClick={inviteUser}
                        >
                            Invite user
                        </button>
                        <button
                            className="text-[#344054] text-base font-semibold border-[var(--gray-300,_#D0D5DD)] text-center border p-2 px-5 rounded-xl"
                            onClick={() => {
                                toggleInviteModal(false)
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <div
                    className="fixed top-0 left-0 w-full h-full bg-transparent z-[60]"
                    onClick={() => {
                        toggleInviteModal(false)
                    }}
                ></div>
            </div>

            <div
                className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                    removeModal ? 'fixed' : 'hidden'
                }`}
            >
                <div
                    className="relative w-[300px] sm:w-[400px] bg-white p-[20px] py-[20px] rounded-2xl flex flex-col z-[70]"
                    style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                >
                    <div className="flex-1">
                        <h2 className="text-[#101828] text-[20px] font-semibold leading-10 pb-2">
                            Remove user
                        </h2>
                        <p className="text-sm text-[#475467] font-normal pb-4">
                            Are you sure you want to remove this user? Upon removal, they will lose
                            access to links, questions, and content.
                        </p>
                    </div>

                    <div className="flex justify-between gap-3 mt-5">
                        <button
                            className="text-[#101828] text-base font-semibold text-center p-2 px-5 flex-1"
                            onClick={toggleRemoveModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white text-base font-semibold bg-[#D92D20] p-2 px-5 rounded-xl text-center shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] flex-1"
                            onClick={removeProfile}
                        >
                            Yes, delete user
                        </button>
                    </div>
                </div>
                <div
                    className="fixed top-0 left-0 w-full h-full bg-transparent z-[60]"
                    onClick={toggleRemoveModal}
                ></div>
            </div>
        </div>
    )
}

export default Users
