import { BrandApi } from '../api/brand'
import RecorderButton from '../components/RecorderButton'
import { useUserStore } from '../context/user/store'
import { UserForm } from '../types/User'
import { defaultBrandBgColor } from '../utils/constant'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'

const EditProfile = (): JSX.Element => {
    const {
        state: { user },
        setUser,
        setLoading,
    } = useUserStore()
    const [colorPickerShow, setColorPickerShow] = useState<boolean>(false)
    const [finalColor, setFinalColor] = useState<string>(
        user?.brand?.background_color || defaultBrandBgColor,
    )
    const [bgSrc, setBgSrc] = useState<string>(user?.brand?.background_image_url)
    const [bgSrcMobil, setBgSrcMobile] = useState<string>(user?.brand?.background_image_mobile)
    const [bgFile, setBgFile] = useState(null)
    const [bgFileMobile, setBgFileMobile] = useState(null)
    const [avatarSrc, setAvatarSrc] = useState<string>(user?.profile_photo?.url)
    const [avatarFile, setAvatarFile] = useState(null)

    const [form, setForm] = useState<UserForm>({
        firstName: user.first_name ?? '',
        lastName: user.last_name ?? '',
        bio: user.bio,
    })
    const bgRef = useRef<HTMLInputElement>(null)
    const bgRefMobile = useRef<HTMLInputElement>(null)
    const avatarRef = useRef<HTMLInputElement>(null)

    const [pickColor, setPickColor] = useState<string>('#000000')
    const [colorValue, setColorValue] = useState<string>(pickColor?.replace('#', ''))
    const [colorError, setColorError] = useState<string>('')
    const [latestChange, setLatestChange] = useState<'bgColor' | 'bgImage' | null>(null)

    const navigate = useNavigate()

    const confirmColor = () => {
        if (colorValue.length === 3 || colorValue.length === 6) {
            setColorPickerShow(false)
            setFinalColor(pickColor)
            setBgSrc('')
            setBgSrcMobile('')
            setBgFile(null)
            setPickColor('#000000')
            setColorValue('000000')
            setColorError('')
            setLatestChange('bgColor')
        } else {
            setColorError('Hm, I can’t find that HEX code...')
        }
    }

    const cancelColor = () => {
        setColorPickerShow(false)
        setPickColor('#000000')
        setColorValue('000000')
        setColorError('')
    }

    const colorKeyClicked = (e: any) => {
        if (
            (((e.keyCode >= 71 && e.keyCode <= 90) || (e.keyCode >= 103 && e.keyCode <= 122)) &&
                !e.ctrlKey) ||
            e.key === ' '
        ) {
            e.preventDefault()
            return false
        }
    }

    const inputColorValue = (e: any) => {
        let initValue = e.target.value
        if (initValue[0] === '#') initValue = initValue.substring(1)
        let val = initValue.substring(0, 6)
        if (initValue.length === 7 && initValue[0] === '#') val = initValue.substring(1, 7)
        setColorValue(val)
        if (val.length === 3 || val.length === 6) setPickColor('#' + val)
    }

    const changeBgImg = (e: any) => {
        if (e.target.files.length > 0) {
            setBgFile(e.target.files[0])
            setBgSrc(URL.createObjectURL(e.target.files[0]))
            setLatestChange('bgImage')
        }
    }

    const changeBgImgMobile = (e: any) => {
        if (e.target.files.length > 0) {
            setBgFileMobile(e.target.files[0])
            setBgSrcMobile(URL.createObjectURL(e.target.files[0]))
            setLatestChange('bgImage')
        }
    }

    const changeAvatar = (e: any) => {
        if (e.target.files.length > 0) {
            setAvatarSrc(URL.createObjectURL(e.target.files[0]))
            setAvatarFile(e.target.files[0])
        }
    }

    const saveProfile = async () => {
        setLoading(true)
        try {
            const color = finalColor !== defaultBrandBgColor ? finalColor : undefined
            let image: string | null | undefined
            let imageMobile: string | null | undefined
            image = bgFile
            imageMobile = bgFileMobile
            const promiseApis: any = [
                BrandApi.setBrand({
                    background_color: latestChange === 'bgColor' ? color : undefined,
                    background_image: latestChange === 'bgImage' ? image : undefined,
                    background_image_mobile: latestChange === 'bgImage' ? imageMobile : undefined,
                }),
                BrandApi.saveProfile({
                    first_name: form.firstName,
                    last_name: form.lastName,
                    bio: form.bio,
                }),
            ]

            if (avatarFile)
                promiseApis.push(
                    BrandApi.setProfilePhotos({
                        file: avatarFile,
                        audio_length: '',
                    }),
                )

            const [brandResponse, profileResponse, profilePhotoResponse] = await Promise.all(
                promiseApis,
            )
            setLoading(false)

            let resUser = { ...user }

            if (brandResponse?.status === 200) {
                resUser.brand = brandResponse.data
            }

            if (profileResponse?.status === 200) {
                resUser.first_name = form.firstName
                resUser.last_name = form.lastName
                resUser.bio = form.bio
            }

            if (profilePhotoResponse?.status === 200) {
                resUser.profile_photo = profilePhotoResponse.data
            }

            setUser(resUser)

            navigate(-1)
        } catch (e) {
            setLoading(false)
        }
    }

    return (
        <div className="flex-1 px-[20px] pb-0 xs:pb-[40px] sm:px-[70px] lg:pr-[120px]">
            <div className="flex justify-between items-center gap-3 sm:gap-8 pl-2 sm:pl-0">
                <h2
                    className="text-[25px] font-bold text-[#8092A5] flex-1"
                    style={{ fontFamily: 'Raleway' }}
                >
                    My Brand
                </h2>
                <a
                    className="text-base text-[#007AFF] font-bold pl-1 cursor-pointer select-none"
                    onClick={() => navigate(-1)}
                >
                    Cancel
                </a>
                <a
                    className="text-base text-[#007AFF] font-bold pl-1 cursor-pointer select-none"
                    onClick={saveProfile}
                >
                    Save
                </a>
            </div>
            <div className="relative flex flex-col h-[calc(100vh_+_210px)] xs:h-[calc(100vh_-_100px)] sm:h-[740px] items-center justify-between my-7 rounded-xl bg-[#FFFFFF]">
                <div
                    className="relative flex flex-col gap-2.5 pt-[30px] w-full px-[25px] sm:px-[30px] pb-[120px] h-[250px] sm:h-[200px] rounded-t-lg"
                    style={{ background: finalColor }}
                >
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-5 z-[7]">
                        <div
                            className="flex w-fit h-[34px] bg-[#FFFFFF] rounded-md items-center cursor-pointer select-none hover:bg-[#F0F8FF]"
                            onClick={() => setColorPickerShow(true)}
                        >
                            <img
                                src="/asset/brush_alt.svg"
                                alt="brush_alt"
                                className="p-[8px] h-full w-[34px] bg-[#6BB2FF52] rounded-md"
                            />
                            <a className="text-base font-bold text-[#007AFF] px-2.5">
                                Select color
                            </a>
                        </div>
                        {colorPickerShow && (
                            <div className="flex p-[3px] justify-between h-fit xs:h-[34px] bg-[#D9D9D9] rounded-md  cursor-pointer select-none flex-col xs:flex-row">
                                <div className="flex items-center">
                                    <input
                                        className="bg-[#FFFFFF7D] text-[#001F40]  placeholder:text-[#001F407D] max-w-[100px] flex-1 h-full rounded-md px-2 focus:outline-none pl-[19px]"
                                        placeholder="HEX #"
                                        value={colorValue}
                                        onChange={inputColorValue}
                                        onKeyDown={colorKeyClicked}
                                    />
                                    <div
                                        className={`w-[30px] h-[30px] ml-2 rounded-md`}
                                        style={{ background: pickColor }}
                                    />
                                    <img
                                        src="/asset/check_circle.svg"
                                        alt="check_circle"
                                        className="w-[20px] h-full ml-2"
                                        onClick={confirmColor}
                                    />
                                    <img
                                        src="/asset/highlight_off.svg"
                                        alt="highlight_off"
                                        className="w-[20px] h-full ml-[2px] mr-1"
                                        onClick={cancelColor}
                                    />
                                </div>
                                {colorError && (
                                    <p
                                        className="mt-1 xs:mt-0 ml-1 mr-2 text-[#0D0D0D] text-base overflow-hidden text-ellipsis whitespace-pre flex-1"
                                        style={{ lineHeight: 1.8 }}
                                    >
                                        {colorError}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                    <div
                        className="flex w-fit h-[34px] bg-[#FFFFFF] rounded-md items-center cursor-pointer select-none hover:bg-[#F0F8FF] z-[7]"
                        onClick={() => bgRef.current?.click()}
                    >
                        <img
                            src="/asset/camera_alt.svg"
                            alt="camera_alt"
                            className="p-[8px] h-full w-[34px] bg-[#6BB2FF52] rounded-md"
                        />
                        <a className="text-base font-bold text-[#007AFF] px-2.5">Upload photo</a>
                        <input
                            accept="image/*"
                            type="file"
                            className="hidden"
                            ref={bgRef}
                            onChange={changeBgImg}
                        />
                    </div>
                    <div
                        className="flex w-fit h-[34px] bg-[#FFFFFF] rounded-md items-center cursor-pointer select-none hover:bg-[#F0F8FF] z-[7]"
                        onClick={() => bgRefMobile.current?.click()}
                    >
                        <img
                            src="/asset/camera_alt.svg"
                            alt="camera_alt"
                            className="p-[8px] h-full w-[34px] bg-[#6BB2FF52] rounded-md"
                        />
                        <a className="text-base font-bold text-[#007AFF] px-2.5">
                            Upload photo (mobile)
                        </a>
                        <input
                            accept="image/*"
                            type="file"
                            className="hidden"
                            ref={bgRefMobile}
                            onChange={changeBgImgMobile}
                        />
                    </div>
                    <p className="text-xs italic text-[#007AFF] bg-gray-300 w-fit z-10">
                        Note: If image, we recommend an
                        <br />
                        aspect ratio of 4:1 for web and 3:2 for mobile
                    </p>
                    {bgSrc && (
                        <img
                            src={bgSrc}
                            className="w-full h-full absolute object-cover mt-[-30px] mx-[-25px] sm:mx-[-50px] sm:ml-[-30px] mb-[-120px] z-[5]"
                        />
                    )}
                </div>
                <div className="absolute top-[210px] xs:top-[180px] sm:top-[150px] px-[25px] w-full md:w-fit z-[6]">
                    <div
                        className="w-full md:w-[580px] xl:w-[720px] px-[25px] xs:px-[45px] py-10 pb-8 xs:pb-12 rounded-lg"
                        style={{
                            background:
                                'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                        }}
                    >
                        <React.Fragment>
                            <div className="flex flex-col xs:flex-row gap-4">
                                <div
                                    className="relative flex flex-row items-center justify-center hover:opacity-100 opacity-80 mb-3 xs:mb-0"
                                    onClick={() => avatarRef.current?.click()}
                                >
                                    <img
                                        src={
                                            avatarSrc ??
                                            'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                        }
                                        width={40}
                                        height={40}
                                        className="z-40 w-10 h-10 rounded-full object-cover"
                                        alt="profile_photo"
                                    />
                                    <img
                                        src="/asset/camera_alt.svg"
                                        width={40}
                                        height={40}
                                        className="z-50 w-5 h-5 absolute opacity-70 hover:opacity-100"
                                        alt="camera_alt"
                                    />
                                </div>
                                <input
                                    accept="image/*"
                                    type="file"
                                    className="hidden"
                                    ref={avatarRef}
                                    onChange={changeAvatar}
                                />
                                <div className="flex flex-col gap-4 justify-center flex-1">
                                    <div className="flex flex-col xs:flex-row w-full gap-4 xs:gap-0">
                                        <input
                                            value={form.firstName}
                                            placeholder="First name"
                                            className="w-full text-base text-[#001F40] placeholder:text-[#AFAFAF] bg-white  focus:outline-none px-6 py-2.5 rounded-[10px] mr-0 xs:mr-[7px]"
                                            style={{
                                                backgroundColor: form.firstName
                                                    ? 'white'
                                                    : '#d9d9d975',
                                                color: form.firstName ? '#001F40' : 'white',
                                            }}
                                            onChange={(e) => {
                                                setForm((form) => ({
                                                    ...form,
                                                    firstName: e.target.value,
                                                }))
                                            }}
                                        />

                                        <input
                                            value={form.lastName}
                                            placeholder="Last name"
                                            className="w-full text-base text-[#001F40] placeholder:text-[#AFAFAF] bg-white focus:outline-none px-6 py-2.5 rounded-[10px] ml-0 xs:ml-[7px]"
                                            style={{
                                                backgroundColor: form.lastName
                                                    ? 'white'
                                                    : '#d9d9d975',
                                                color: form.lastName ? '#001F40' : 'white',
                                            }}
                                            onChange={(e) => {
                                                setForm((form) => ({
                                                    ...form,
                                                    lastName: e.target.value,
                                                }))
                                            }}
                                        />
                                    </div>

                                    <TextareaAutosize
                                        className="w-full text-base text-[#001F40] placeholder:text-[#AFAFAF] bg-white px-6 py-2.5 rounded-[10px] focus:outline-none resize-none border-none"
                                        placeholder="Your bio..."
                                        value={form.bio}
                                        style={{
                                            backgroundColor: form.bio ? 'white' : '#d9d9d975',
                                            color: form.bio ? '#001F40' : 'white',
                                        }}
                                        onChange={(e) => {
                                            setForm((form) => ({
                                                ...form,
                                                bio: e.target.value,
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-5 xs:mt-3.5">
                                <p className="text-base text-[#DADADABD]">Your question...</p>
                            </div>
                        </React.Fragment>
                    </div>
                    <div className="flex flex-col items-center mt-[60px] pb-[50px]">
                        <RecorderButton
                            onStop={() => {}}
                            onRecorderNotSupported={() => {}}
                            disabled={true}
                            tooltip="Recorder is disabled in preview mode."
                        />
                    </div>
                </div>
                {/* MOBILE VIEW*/}
                <div className="w-[100px] h-[100px] absolute -right-10 -bottom-10 w-[360px] h-[720px] z-[99] hidden lg:block">
                    <div
                        className="relative flex flex-col h-[calc(100vh_+_210px)] xs:h-[calc(100vh_-_100px)] sm:h-[740px] items-center justify-between rounded-xl bg-[#FFFFFF] overflow-y-scroll"
                        style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                    >
                        <div
                            className="relative flex flex-col gap-2.5 pt-[30px] w-full px-[25px] pt-[25px] pb-[120px] h-[250px] rounded-t-lg"
                            style={{ background: finalColor }}
                        >
                            {bgSrcMobil && (
                                <img
                                    src={bgSrcMobil}
                                    className="w-full h-full absolute object-cover mt-[-30px] mx-[-25px] sm:mx-[-50px] sm:ml-[-30px] mb-[-120px] z-[5]"
                                />
                            )}
                        </div>
                        <div className="absolute top-[210px] xs:top-[180px] sm:top-[150px] px-[25px] w-full md:w-fit z-[6]">
                            <div
                                className="w-full py-10 pb-8 rounded-lg"
                                style={{
                                    background:
                                        'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                                }}
                            >
                                <React.Fragment>
                                    <div className="flex flex-col gap-4 px-[25px]">
                                        <div className="relative flex flex-row items-center justify-center hover:opacity-100 opacity-80 mb-3 xs:mb-0">
                                            <img
                                                src={
                                                    avatarSrc ??
                                                    'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                                }
                                                width={40}
                                                height={40}
                                                className="z-40 w-10 h-10 rounded-full object-cover"
                                                alt="profile_photo"
                                            />
                                        </div>
                                        <input
                                            accept="image/*"
                                            type="file"
                                            className="hidden"
                                            ref={avatarRef}
                                            onChange={changeAvatar}
                                        />
                                        <div className="flex flex-col gap-4 justify-center flex-1">
                                            <div className="flex flex-col w-full gap-4">
                                                <input
                                                    value={form.firstName}
                                                    placeholder="First name"
                                                    className="w-full text-base text-[#001F40] placeholder:text-[#AFAFAF] bg-white  focus:outline-none px-6 py-2.5 rounded-[10px]"
                                                    style={{
                                                        backgroundColor: form.firstName
                                                            ? 'white'
                                                            : '#d9d9d975',
                                                        color: form.firstName ? '#001F40' : 'white',
                                                    }}
                                                    onChange={(e) => {
                                                        setForm((form) => ({
                                                            ...form,
                                                            firstName: e.target.value,
                                                        }))
                                                    }}
                                                />

                                                <input
                                                    value={form.lastName}
                                                    placeholder="Last name"
                                                    className="w-full text-base text-[#001F40] placeholder:text-[#AFAFAF] bg-white focus:outline-none px-6 py-2.5 rounded-[10px]"
                                                    style={{
                                                        backgroundColor: form.lastName
                                                            ? 'white'
                                                            : '#d9d9d975',
                                                        color: form.lastName ? '#001F40' : 'white',
                                                    }}
                                                    onChange={(e) => {
                                                        setForm((form) => ({
                                                            ...form,
                                                            lastName: e.target.value,
                                                        }))
                                                    }}
                                                />
                                            </div>

                                            <TextareaAutosize
                                                className="w-full text-base text-[#001F40] placeholder:text-[#AFAFAF] bg-white px-6 py-2.5 rounded-[10px] focus:outline-none resize-none border-none"
                                                placeholder="Your bio..."
                                                value={form.bio}
                                                style={{
                                                    backgroundColor: form.bio
                                                        ? 'white'
                                                        : '#d9d9d975',
                                                    color: form.bio ? '#001F40' : 'white',
                                                }}
                                                onChange={(e) => {
                                                    setForm((form) => ({
                                                        ...form,
                                                        bio: e.target.value,
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <p className="text-base text-[#DADADABD] pl-[25px]">
                                            Your question...
                                        </p>
                                    </div>
                                </React.Fragment>
                            </div>
                            <div className="flex flex-col items-center mt-[60px] pb-[50px]">
                                <RecorderButton
                                    onStop={() => {}}
                                    onRecorderNotSupported={() => {}}
                                    disabled={true}
                                    tooltip="Recorder is disabled in preview mode."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfile
