import { CampaignApi } from '../api/campaign'
import { ClipsApi } from '../api/clips'
import CampaignDataSourcesModal from '../components/CampaignDataSourcesModal'
import ClipCard from '../components/ClipCard'
import FolderCard from '../components/FolderCard'
import MeetingLinkModal from '../components/MeetingLinkModal'
import Spinner from '../components/Spinner'
import SurveyCard from '../components/SurveyCard'
import Chat from '../components/chat/Chat'
import { useUserStore } from '../context/user/store'
import { CampaignType } from '../enums/CampaignType'
import { Page } from '../enums/Page'
import { CampaignDetails, CampaignData } from '../types/Campaign'
import { CampaignDataSourceItem } from '../types/CampaignDataSources'
import { Clip } from '../types/Clip'
import { ClipsResponse, ClipsTopicsResponse } from '../types/ClipsResponse'
import { useEffect, useRef, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import { createPath, generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const CampaignLandingPage = (): JSX.Element => {
    const { username, id } = useParams<{ username: string; id: string }>()
    const [campaign, setCampaign] = useState<CampaignDetails | null>(null)
    const [campaignFoldersData, setCampaignFoldersData] = useState<CampaignData[] | null>(null)
    const [campaignSurveysData, setCampaignSurveysData] = useState<CampaignData[] | null>(null)
    const [campaignId, setCampaignId] = useState<string>('')
    const [editingTitle, setEditingTitle] = useState<boolean>(false)
    const [editedTitle, setEditedTitle] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [showMeetingLinkModal, setShowMeetingLinkModal] = useState<boolean>(false)
    const [foldersCount, setFoldersCount] = useState<number>(0)
    const [surveysCount, setSurveysCount] = useState<number>(0)
    const [selectedCampaignType, setSelectedCampaignType] = useState<CampaignType | null>(null)
    const [showAllClips, setShowAllClips] = useState<boolean>(false)
    const [showAllFolders, setShowAllFolders] = useState<boolean>(false)
    const [showAllSurveys, setShowAllSurveys] = useState<boolean>(false)
    // eslint-disable-next-line no-unused-vars
    const [clipsTopics, setClipsTopics] = useState<ClipsTopicsResponse | null>(null)
    const [clips, setClips] = useState<ClipsResponse | null>(null)
    const player = useRef<AudioPlayer | null>(null)
    // eslint-disable-next-line no-unused-vars
    const [selectedTopic, setSelectedTopic] = useState<string>('')
    const [topicJustLoaded, setTopicJustLoaded] = useState<boolean>(true)
    const [selectedClip, setSelectedClip] = useState<Clip | null>()
    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const navigate = useNavigate()
    const inputRef = useRef<HTMLInputElement | null>(null)
    const {
        state: { user },
    } = useUserStore()
    const [updateCampaignDataSourceModalOpen, setUpdateCampaignDataSourceModalOpen] =
        useState<boolean>(false)

    //const TEMP_CAMPAIGN_ID = '5f2dfc61-a3d7-4a84-9be3-3b10a4be8742'

    const toggleUpdateCampaignDataSourceModal = (campaignType: CampaignType | null) => {
        setSelectedCampaignType(campaignType)
        setUpdateCampaignDataSourceModalOpen(!updateCampaignDataSourceModalOpen)
    }

    const fetchClipsByTopic = async (topic: string, campaignID: string) => {
        try {
            setLoading(true)
            if (topic && campaignID) {
                const response = await ClipsApi.getClipsByCampaignIdAndTopicName(campaignID, topic)
                setClips(response?.data || null)
                //select the first clip to play
                if (response?.data?.clips && response.data.clips.length > 0) {
                    setTopicJustLoaded(true)
                    setSelectedClip(response?.data?.clips[0] || null)

                    setTimeout(() => {
                        setTopicJustLoaded(false)
                    }, 1000)
                }
            }
        } catch (err) {
            setClips(null)
        } finally {
            setLoading(false)
        }
    }

    const fetchClipsTopics = async (campaignId: string, loadInitialTopic: boolean) => {
        //use temp campaign id for testing
        try {
            setLoading(true)
            if (campaignId) {
                const response = await ClipsApi.getClipsTopicsByCampaignId(campaignId)
                setClipsTopics(response?.data || null)
                if (loadInitialTopic && response?.data?.topics.length > 0) {
                    await fetchClipsByTopic(response.data.topics[0], campaignId)
                    setSelectedTopic(response.data.topics[0])
                }
            } else {
                setClipsTopics(null)
            }
        } catch (err) {
            setClipsTopics(null)
        } finally {
            setLoading(false)
        }
    }

    const fetchCampaign = async (id: string) => {
        try {
            setLoading(true)
            const response = await CampaignApi.getCampaign(id)
            setCampaign(response.data)
            setCampaignId(response.data.id)
            if (!response.data.title || response.data.title === '') {
                setEditingTitle(true)
            }
            //loop through campaignData and count the number of folders and surveys
            let folders = 0
            let surveys = 0
            let campFoldersData: CampaignData[] = []
            let campSurveysData: CampaignData[] = []
            response.data.campaignData.forEach((data) => {
                if (data.type === CampaignType.LIVE) {
                    folders++
                    campFoldersData.push(data)
                } else if (data.type === CampaignType.ASYNC) {
                    surveys++
                    campSurveysData.push(data)
                }
            })
            setFoldersCount(folders)
            setCampaignFoldersData(campFoldersData)
            setSurveysCount(surveys)
            setCampaignSurveysData(campSurveysData)

            await fetchClipsTopics(id, true)
        } catch (err) {
            setCampaign(null)
            toast.error('Unable to load this campaign. Please try again.', {
                className: 'z-[999999] mt-5',
            })
        } finally {
            setLoading(false)
        }
    }

    const addCampaignData = async (campaignDataSources: CampaignDataSourceItem[]) => {
        if (campaignId) {
            try {
                setLoading(true)
                if (campaignDataSources.length > 0) {
                    const payload = campaignDataSources.map((campaignDataSource) => ({
                        campaign_id: campaignId,
                        interview_id:
                            campaignDataSource.type === 'live' ? campaignDataSource.id : null,
                        survey_id:
                            campaignDataSource.type === 'async' ? campaignDataSource.id : null,
                    }))
                    await CampaignApi.updateDataSourcesForCampaign(campaignId, {
                        selected_data_sources: payload,
                    })
                } else {
                    //delete all data sources for this campaign type if none are selected
                    await CampaignApi.deleteCampaignDataSourcesByType(
                        campaignId,
                        selectedCampaignType || '',
                    )
                }
                toast.success(
                    <p className="text-[#027A48] text-sm font-semibold">
                        Campaign data sources have beed updated successfully!
                    </p>,
                    {
                        className: 'z-[999999] mt-5',
                        icon: <img src="/asset/green_check.svg" />,
                    },
                )
                fetchCampaign(campaignId)
            } finally {
                setLoading(false)
            }
        }
        toggleUpdateCampaignDataSourceModal(null)
    }

    const validateCampaignTitleExists = () => {
        if (editedTitle === '') {
            setEditingTitle(true)
            toast.error('Please specify a title for the campaign.', {
                className: 'z-[999999] mt-30',
                autoClose: 3000,
                pauseOnHover: true,
            })
        } else {
            setEditingTitle(false)
        }
    }

    const updateUrl = (campaignId: string) => {
        navigate(
            generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                username: username || '',
                id: campaignId || '',
            }),
            { replace: true },
        )
    }

    const updateCampaignTitle = async () => {
        if (id === 'new') {
            if (editedTitle !== '') {
                setLoading(true)
                const response = await CampaignApi.saveCampaign(editedTitle)
                setCampaign(response.data)
                updateUrl(response.data.id)
                setLoading(false)
                setEditingTitle(false)
                toast.success('Campaign created successfully.', {
                    className: 'z-[999999] mt-30',
                    autoClose: 3000,
                    pauseOnHover: true,
                })
            } else {
                setEditingTitle(true)
                toast.error('Please specify a title for the campaign.', {
                    className: 'z-[999999] mt-30',
                    autoClose: 3000,
                    pauseOnHover: true,
                })
            }
        } else {
            if (campaign?.id && editedTitle !== '') {
                setLoading(true)
                await CampaignApi.saveCampaignTitle(campaignId, editedTitle)
                await fetchCampaign(campaignId)
                setLoading(false)
                setEditingTitle(false)
                toast.success('Campaign updated successfully.', {
                    className: 'z-[999999] mt-5',
                })
            } else if (editedTitle === '') {
                setEditingTitle(true)
                toast.error('Please specify a title for the campaign.', {
                    className: 'z-[999999] mt-5',
                })
            }
        }
    }

    const onEditTitleEnabled = () => {
        setEditingTitle(true)
        setEditedTitle(campaign?.title ?? '')
        if (inputRef && inputRef.current) {
            inputRef.current.focus()
        }
    }

    const captureNewDataClicked = (campaignType: CampaignType | null) => {
        setSelectedCampaignType(campaignType)
        navigate(
            generatePath(Page.QUESTION_CREATE, {
                username: user.username,
                campaignId: campaign?.id || null,
            }),
            {
                state: {
                    campaignType: campaignType,
                },
            },
        )
    }

    const onTopicSelected = (topic: string) => {
        setSelectedTopic(topic)
        fetchClipsByTopic(topic, campaignId)
    }

    const shareClips = async () => {
        setLoading(true)
        try {
            const response = await ClipsApi.suggestVideo(campaignId, selectedTopic)
            if (response.data) {
                //navigate to the share clips page
                navigate(
                    createPath({
                        pathname: `/reels/${campaignId}/${selectedTopic}/create`,
                        search: `clip_ids=${response?.data?.clip_ids?.join(',')}`,
                    }),
                    {
                        state: {
                            username: username,
                        },
                    },
                )
            }
        } finally {
            setLoading(false)
        }
    }

    const playerClickPrevious = () => {
        if (selectedClip) {
            const currentIndex = clips?.clips.findIndex((clip) => clip.id === selectedClip.id)
            if (currentIndex && currentIndex > 0) {
                const previousClip = clips?.clips[currentIndex - 1]
                setSelectedClip(previousClip)
            }
        }
    }

    const playerClickNext = () => {
        if (selectedClip) {
            let currentIndex = clips?.clips.findIndex((clip) => clip.id === selectedClip.id)
            if (!currentIndex) {
                currentIndex = 0
            }
            if (clips && clips.clips && currentIndex < clips.clips.length - 1) {
                const nextClip = clips?.clips[currentIndex + 1]
                setSelectedClip(nextClip)
            }
        }
    }

    useEffect(() => {
        if (id === 'new') {
            setCampaign(null)
            setEditingTitle(true)
        } else if (id) {
            fetchCampaign(id)
        }
    }, [])

    useEffect(() => {
        if (updateCampaignDataSourceModalOpen) {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = 'auto'
        }

        return () => {
            document.body.style.overflowY = 'hidden'
        }
    }, [updateCampaignDataSourceModalOpen])

    useEffect(() => {
        if (!topicJustLoaded) {
            // eslint-disable-next-line no-console
            //console.log('Playing selected topic...')
            player.current?.playAudioPromise()
        }
    }, [selectedClip])

    return (
        <div className="flex-1 flex flex-col pl-5 pr-3 sm:px-[70px] lg:pr-[120px]">
            <>{loading && <Spinner />}</>
            <div className="flex flex-row justify-between items-center mb-10">
                <div className="flex flex-row items-center w-3/5">
                    <button
                        className="text-[#007AFF] font-lato font-bold text-base pr-3"
                        onClick={() => navigate(Page.HOME)}
                    >
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.93934 13.9393C8.35355 14.5251 8.35355 15.4749 8.93934 16.0607L18.4853 25.6066C19.0711 26.1924 20.0208 26.1924 20.6066 25.6066C21.1924 25.0208 21.1924 24.0711 20.6066 23.4853L12.1213 15L20.6066 6.51472C21.1924 5.92893 21.1924 4.97919 20.6066 4.3934C20.0208 3.80761 19.0711 3.80761 18.4853 4.3934L8.93934 13.9393ZM12 13.5H10V16.5H12V13.5Z"
                                fill="#8092A5"
                            />
                        </svg>
                    </button>
                    {editingTitle ? (
                        <input
                            ref={inputRef}
                            className="w-fit sm:w-full text-[16px] sm:text-[25px] font-bold text-[#8092A5] outline-none focus-within:outline-none bg-transparent"
                            value={editedTitle}
                            placeholder="Please enter a title for your campaign"
                            onChange={(e) => setEditedTitle(e.target.value)}
                            onFocus={(e) =>
                                e.currentTarget.setSelectionRange(
                                    e.currentTarget.value.length,
                                    e.currentTarget.value.length,
                                )
                            }
                        />
                    ) : (
                        <div className="flex flex-row items-center">
                            <h2 className="w-fit sm:w-full text-[16px] sm:text-[25px] font-bold text-[#8092A5] ellipsis-1 break-all">
                                {campaign?.title}
                            </h2>
                            <button className="p-1 sm:p-2" onClick={onEditTitleEnabled}>
                                <img src="/asset/pencil.svg" width={28} height={28} alt="pencil" />
                            </button>
                        </div>
                    )}
                </div>
                {editingTitle ? (
                    <div className="flex flex-row items-center gap-2 sm:gap-4">
                        <button
                            className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                            onClick={validateCampaignTitleExists}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                            onClick={() => updateCampaignTitle()}
                        >
                            Save
                        </button>
                    </div>
                ) : (
                    <div />
                )}
            </div>
            <div className="w-full flex flex-col sm:flex-row gap-8 sm:gap:0">
                <div className="w-full sm:w-1/2 xl:w-[70%] order-2 sm:order-1">
                    <div className="mr-5 mt-3 mb-5 z-0 border-none border-[#F7F7F7]">
                        <AudioPlayer
                            ref={player}
                            src={selectedClip?.url}
                            customProgressBarSection={[
                                RHAP_UI.MAIN_CONTROLS,
                                RHAP_UI.PROGRESS_BAR,
                                RHAP_UI.CURRENT_TIME,
                                <div>/</div>,
                                RHAP_UI.DURATION,
                            ]}
                            onPlay={() => {
                                setIsPlaying(true)
                            }}
                            onPause={() => {
                                setIsPlaying(false)
                            }}
                            onEnded={() => {
                                setIsPlaying(false)
                            }}
                            showJumpControls={true}
                            showSkipControls={true}
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            customControlsSection={[]}
                            timeFormat="mm:ss"
                            className="customized-audio-player border-none rounded-lg bg-[#F7F7F7]"
                            loop={false}
                            autoPlay={false}
                            autoPlayAfterSrcChange={false}
                            onPlayError={() => {}}
                            onClickPrevious={playerClickPrevious}
                            onClickNext={playerClickNext}
                        />
                    </div>
                    <div className="flex flex-col gap-4 mt-10">
                        <div className="flex flex-wrap justify-between items-center gap-4">
                            {clipsTopics && clipsTopics.topics.length > 0 ? (
                                <div className="flex flex-wrap gap-2">
                                    {clipsTopics.topics
                                        .slice(0, 5)
                                        .map((topic: string, index: number) => {
                                            return (
                                                <button
                                                    key={index}
                                                    onClick={() => {
                                                        onTopicSelected(topic)
                                                    }}
                                                    className={`text-sm rounded px-4 py-2 ${
                                                        topic === selectedTopic
                                                            ? 'bg-[#007AFF] text-white'
                                                            : 'bg-[#D9D9D9] text-black'
                                                    }`}
                                                >
                                                    {topic}
                                                </button>
                                            )
                                        })}
                                </div>
                            ) : (
                                <div />
                            )}
                            <div className="shadow-2xl mt-4 sm:mt-0 sm:flex-shrink-0 sm:w-auto w-full">
                                <button
                                    onClick={() => {
                                        shareClips()
                                    }}
                                    className="flex w-full sm:w-[160px] h-[40px] px-4 py-2 bg-[#007AFF] rounded-full text-white justify-center items-center drop-shadow-2xl"
                                >
                                    <img src="/asset/share-clips-icon.svg" />
                                    <p className="ml-2">Share Clips</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 mt-10">
                        <h2 className="items-center text-[22px] font-bold text-[#8092A5]">Clips</h2>
                        <span className="text-[22px] text-[#8092A5] flex items-center justify-center">
                            ({clips?.pagination?.total_items || 0})
                        </span>
                    </div>
                    <div className="flex-1">
                        <div className="mt-5 flex flex-col gap-1 overflow-y-auto pr-2 pb-2">
                            {!clips || !clips.clips || clips.clips.length === 0 ? (
                                <div className="flex-1 pt-[185px] text-center">
                                    {/* <p className="text-[25px] text-[#8092A5]">💫 Voices coming soon</p> */}
                                </div>
                            ) : (
                                clips?.clips
                                    .slice(0, showAllClips ? clips?.clips.length : 10)
                                    .map((clipData: Clip) => (
                                        <div
                                            key={clipData.id}
                                            className="cursor-pointer"
                                            onClick={() => {}}
                                        >
                                            <ClipCard
                                                clipData={clipData}
                                                selectedClipId={selectedClip?.id ?? ''}
                                                isPlaying={
                                                    isPlaying && selectedClip?.id === clipData.id
                                                }
                                                onClipSelected={() => {
                                                    if (selectedClip?.id === clipData.id) {
                                                        player.current?.playAudioPromise()
                                                    } else if (clipData) {
                                                        setSelectedClip(clipData)
                                                    }
                                                }}
                                                onClipPlayingStopped={() => {
                                                    setIsPlaying(false)
                                                    player.current?.audio.current?.pause()
                                                }}
                                            />
                                        </div>
                                    ))
                            )}
                        </div>
                    </div>
                    {clips && clips.clips && clips.clips.length > 0 && (
                        <div className="flex flex-row justify-end items-center mb-4">
                            {clips && clips.clips && clips.clips.length > 10 && (
                                <button
                                    className="font-lato text-sm text-[#007AFF] pb-1 pl-3 pr-4 mr-12"
                                    onClick={() => setShowAllClips(!showAllClips)}
                                >
                                    {showAllClips ? 'View less' : 'View all'}
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <div className="w-full sm:w-1/2 xl:w-[30%] order-1 sm:order-2">
                    {id && <Chat campaignId={id} />}
                </div>
            </div>
            <div className="w-full order-2 sm:order-1">
                <div className="flex justify-between items-center gap-4 mt-8">
                    <div className="flex flex-row gap-2">
                        <h2 className="items-center text-[22px] font-bold text-[#8092A5]">
                            Folders
                        </h2>
                        <span className="text-[22px] text-[#8092A5] flex items-center justify-center">
                            ({foldersCount})
                        </span>
                    </div>
                    <div className="flex flex-row gap-5">
                        <button
                            className="flex justify-center items-center text-sm text-[#007AFF] pb-1 pl-3 pr-4"
                            onClick={() => toggleUpdateCampaignDataSourceModal(CampaignType.LIVE)}
                        >
                            Update
                        </button>
                        <button
                            className="flex justify-center items-center text-sm text-[#007AFF] pb-1 pl-3 pr-4"
                            onClick={() => captureNewDataClicked(CampaignType.LIVE)}
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="flex-1">
                    {foldersCount > 0 && (
                        <div className="mt-5 flex flex-col gap-1 overflow-y-auto pb-4 pr-2">
                            {foldersCount === 0 ? (
                                <div className="flex-1 pt-[185px] text-center">
                                    {/* <p className="text-[25px] text-[#8092A5]">💫 Voices coming soon</p> */}
                                </div>
                            ) : (
                                campaignFoldersData &&
                                campaignFoldersData.length > 0 &&
                                campaignFoldersData
                                    .slice(0, showAllFolders ? foldersCount : 3)
                                    .map((campaignDataObj: CampaignData) => (
                                        <div
                                            key={campaignDataObj.id}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                if (campaignDataObj.type === CampaignType.LIVE) {
                                                    navigate(
                                                        generatePath(Page.INTERVIEW_LANDING_PAGE, {
                                                            username: user?.username,
                                                            slug: campaignDataObj.slug,
                                                        }),
                                                        {
                                                            state: {
                                                                campaignId: campaignId,
                                                                userId: user?.id,
                                                            },
                                                        },
                                                    )
                                                } else {
                                                    navigate(
                                                        generatePath(Page.QUESTION_LANDING_PAGE, {
                                                            username: user?.username,
                                                            slug: campaignDataObj.slug,
                                                        }),
                                                        {
                                                            state: {
                                                                campaignId: campaignId,
                                                            },
                                                        },
                                                    )
                                                }
                                            }}
                                        >
                                            {campaignDataObj.type === CampaignType.LIVE && (
                                                <FolderCard folderData={campaignDataObj} />
                                            )}
                                        </div>
                                    ))
                            )}
                        </div>
                    )}
                </div>
                {foldersCount > 0 && (
                    <div className="flex flex-row justify-end items-center mb-4">
                        {foldersCount > 3 && (
                            <button
                                className="font-lato text-sm text-[#007AFF] pl-3 pr-4"
                                onClick={() => setShowAllFolders(!showAllFolders)}
                            >
                                {showAllFolders ? 'View less' : 'View all'}
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div className="w-full order-2 sm:order-1">
                <div className="flex justify-between items-center gap-4 mt-8">
                    <div className="flex flex-row gap-2">
                        <h2 className="items-center text-[22px] font-bold text-[#8092A5]">
                            Surveys
                        </h2>
                        <span className="text-[22px] text-[#8092A5] flex items-center justify-center">
                            ({surveysCount})
                        </span>
                    </div>
                    <div className="flex flex-row gap-5">
                        <button
                            className="flex justify-center items-center text-sm text-[#007AFF] py-1 pl-3 pr-4"
                            onClick={() => toggleUpdateCampaignDataSourceModal(CampaignType.ASYNC)}
                        >
                            Update
                        </button>
                        <button
                            className="flex justify-center items-center text-sm text-[#007AFF] py-1 pl-3 pr-4"
                            onClick={() => captureNewDataClicked(CampaignType.ASYNC)}
                        >
                            Add New
                        </button>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="mt-5 flex flex-col gap-1 overflow-y-auto pb-8 pr-2">
                        {surveysCount === 0 ? (
                            <div className="flex-1 pt-[185px] text-center">
                                {/* <p className="text-[25px] text-[#8092A5]">💫 Voices coming soon</p> */}
                            </div>
                        ) : (
                            campaignSurveysData &&
                            campaignSurveysData.length > 0 &&
                            campaignSurveysData
                                .slice(0, showAllSurveys ? surveysCount : 3)
                                .map((campaignDataObj: CampaignData) => (
                                    <div
                                        key={campaignDataObj.id}
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (campaignDataObj.type === CampaignType.LIVE) {
                                                navigate(
                                                    generatePath(Page.INTERVIEW_LANDING_PAGE, {
                                                        username: user?.username,
                                                        slug: campaignDataObj.slug,
                                                    }),
                                                    {
                                                        state: {
                                                            campaignId: campaignId,
                                                        },
                                                    },
                                                )
                                            } else {
                                                navigate(
                                                    generatePath(Page.QUESTION_LANDING_PAGE, {
                                                        username: user?.username,
                                                        slug: campaignDataObj.slug,
                                                    }),
                                                    {
                                                        state: {
                                                            campaignId: campaignId,
                                                        },
                                                    },
                                                )
                                            }
                                        }}
                                    >
                                        {campaignDataObj.type === CampaignType.ASYNC && (
                                            <SurveyCard surveyData={campaignDataObj} />
                                        )}
                                    </div>
                                ))
                        )}
                    </div>
                </div>
                {surveysCount > 0 && (
                    <div className="flex flex-row justify-end items-center mb-4">
                        {surveysCount > 3 && (
                            <button
                                className="font-lato text-sm text-[#007AFF] pl-3 pr-4"
                                onClick={() => setShowAllSurveys(!showAllSurveys)}
                            >
                                {showAllSurveys ? 'View less' : 'View all'}
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div>
                {showMeetingLinkModal && (
                    <MeetingLinkModal
                        onClose={() => setShowMeetingLinkModal(false)}
                        authorId={user?.id || ''}
                        campaignId={campaign?.id || ''}
                    />
                )}
            </div>

            {updateCampaignDataSourceModalOpen && (
                <CampaignDataSourcesModal
                    open={true}
                    user={user}
                    onToggle={() => toggleUpdateCampaignDataSourceModal(selectedCampaignType)}
                    onSuccess={addCampaignData}
                    campaignId={campaign?.id}
                    campaignType={selectedCampaignType}
                />
            )}
        </div>
    )
}

export default CampaignLandingPage
