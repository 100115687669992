/* eslint-disable indent */
import { NodeioApi } from '../api/nodeio'
import { Nodeio } from '../types/Nodeio'
import { Question } from '../types/Question'
import { QuestionTypeSettings } from '../types/QuestionType'
import { isJsonString, reloadAudio } from '../utils/audio'
import {
    getFormattedTranscriptionText,
    getNoTranscriptionText,
} from '../utils/formatTranscriptionText'
import { getLanguageName } from '../utils/getLanguageName'
import IconSpinner from './IconSpinner'
import Player from './Player'
import saveAs from 'file-saver'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'

interface RespondingNodeioCardProps {
    question?: Question
    nodeio: Nodeio
    showFullResponses: boolean
    isExpanded?: boolean
    toggleExpand: (id: string) => void
}

const RespondingNodeioCard = ({
    question,
    nodeio,
    isExpanded,
    toggleExpand,
}: RespondingNodeioCardProps): JSX.Element => {
    const player = useRef<any>()
    const [playNodeio, setPlayNodeio] = useState<Nodeio>(nodeio)
    const [formattedTranscriptionText, setFormattedTranscriptionText] = useState<string | null>('')
    const [noTranscriptionText, setNoTranscriptionText] = useState<string | null>('')

    useEffect(() => {
        setPlayNodeio(nodeio)
        if (nodeio.media?.transcription_json) {
            setFormattedTranscriptionText(
                getFormattedTranscriptionText(nodeio.media.transcription_json),
            )
        }
        if (nodeio?.created_at) {
            setNoTranscriptionText(getNoTranscriptionText(nodeio.created_at))
        }
    }, [nodeio])

    useEffect(() => {
        !isExpanded && player.current && player.current.stop()
    }, [[isExpanded, player]])

    const fullName = useMemo(() => {
        let temp = playNodeio.author?.first_name + '-' + playNodeio.author?.last_name
        if (temp === '-') temp = 'Guest'
        return temp
    }, [playNodeio.author])

    const [loading, setLoading] = useState(false)

    const downloadAudio = async () => {
        try {
            var au = document.createElement('audio')
            au.src = playNodeio.media?.url as string
            au.addEventListener(
                'loadedmetadata',
                async function () {
                    setLoading(true)
                    const res = await NodeioApi.downloadAudio(playNodeio.id as string)
                    const mimeType = res.headers['content-type']
                    if (res.status === 200) {
                        var blob = new Blob([res.data])
                        saveAs(
                            blob,
                            `${fullName}-${moment(playNodeio.media?.created_at).format(
                                'MMDDYYYY',
                            )}.${mimeType?.split('/')[1]}`,
                        )
                    }
                    setLoading(false)
                },
                false,
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div
            className={`flex flex-col rounded-lg cursor-pointer`}
            style={{
                background:
                    'var(--Bg-for-Questions, linear-gradient(312deg, rgba(107, 178, 255, 0.19) 9.85%, rgba(0, 122, 255, 0.13) 35.98%, rgba(107, 178, 255, 0.13) 70.39%, rgba(0, 122, 255, 0.19) 95.04%), #FFF)',
                boxShadow: '0px 2px 10px 0px rgba(0, 31, 64, 0.10)',
            }}
        >
            <div
                className="flex flex-row justify-between items-center gap-5 px-6 py-3.5"
                onClick={() => {
                    // if (
                    //     !(
                    //         (question?.question_settings?.type ===
                    //             QuestionTypeSettings.VOICE_OR_TEXT &&
                    //             !playNodeio.media?.url) ||
                    //         (question?.question_settings.type !==
                    //             QuestionTypeSettings.VOICE_OR_TEXT &&
                    //             question?.question_settings.type !==
                    //                 QuestionTypeSettings.VOICE_ONLY)
                    //     ) &&
                    //     !isExpanded
                    // ) {
                    //     setTimeout(() => {
                    //         player.current?.playAudioPromise()
                    //     }, 1000)
                    // }

                    toggleExpand(playNodeio.id)
                }}
            >
                <div className="flex items-center gap-1.5 flex-1">
                    <p className="text-[#001F40] text-xs sm:text-sm">
                        {!playNodeio.author?.first_name && !playNodeio.author?.last_name
                            ? 'Anonymous'
                            : `${playNodeio.author?.first_name} ${playNodeio.author?.last_name}`}
                    </p>
                    {/*make sure that the nodeio is text based*/}
                    {/* {!playNodeio.media?.url && playNodeio.title && (
                        <>
                            <div
                                className={`bg-[#000000] w-[1px] mx-[4px] sm:mx-[16px] ${
                                    isExpanded &&
                                    !(
                                        (question?.question_settings?.type ===
                                            QuestionTypeSettings.VOICE_OR_TEXT &&
                                            !playNodeio.media?.url) ||
                                        (question?.question_settings.type !==
                                            QuestionTypeSettings.VOICE_OR_TEXT &&
                                            question?.question_settings.type !==
                                                QuestionTypeSettings.VOICE_ONLY)
                                    )
                                        ? 'h-5'
                                        : 'h-9'
                                }`}
                            />
                            <ul
                                className={`text-[#001F40] text-sm sm:text-base font-semibold list-disc list-inside flex-1 ${
                                    isExpanded ? '' : 'ellipsis'
                                }`}
                            >
                                {question?.question_settings?.type ===
                                    QuestionTypeSettings.MULTI_SELECT &&
                                isJsonString(playNodeio?.title)
                                    ? JSON.parse(playNodeio?.title).map(
                                          (option: string, index: number) => (
                                              <li key={index}>{option}</li>
                                          ),
                                      )
                                    : playNodeio?.title}
                            </ul>
                        </>
                    )} */}
                </div>
                <div className="flex flex-row gap-3.5 items-center">
                    <div className="flex flex-col text-right">
                        <p className="text-xs sm:text-sm text-[#001F40]">
                            {moment(new Date()).diff(playNodeio.created_at, 'hours') > 72
                                ? moment(playNodeio.created_at).format('LL')
                                : moment(playNodeio.created_at).fromNow()}
                        </p>
                        {playNodeio.settings?.original_language &&
                            playNodeio.settings?.original_language.toLocaleLowerCase() !== 'en' &&
                            playNodeio.settings?.original_language.toLocaleLowerCase() !==
                                'english' && (
                                <p className="text-[#007AFF] text-xs">
                                    Responded in{' '}
                                    {getLanguageName(playNodeio.settings.original_language)}
                                </p>
                            )}
                        {playNodeio.media?.transcription_language &&
                            playNodeio.media?.transcription_language.toLocaleLowerCase() !== 'en' &&
                            playNodeio.media?.transcription_language.toLocaleLowerCase() !==
                                'english' && (
                                <p className="text-[#007AFF] text-sm">
                                    Recorded in{' '}
                                    {getLanguageName(playNodeio.media.transcription_language)}
                                </p>
                            )}
                    </div>
                    <button
                        style={{
                            display:
                                isExpanded ||
                                (question?.question_settings?.type ===
                                    QuestionTypeSettings.VOICE_OR_TEXT &&
                                    !playNodeio.media?.url) ||
                                (question?.question_settings.type !==
                                    QuestionTypeSettings.VOICE_OR_TEXT &&
                                    question?.question_settings.type !==
                                        QuestionTypeSettings.VOICE_ONLY)
                                    ? 'none'
                                    : 'block',
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            downloadAudio()
                        }}
                        disabled={loading}
                    >
                        {loading ? (
                            <div className="w-6 h-6">
                                <IconSpinner />
                            </div>
                        ) : (
                            <img src="/asset/download-icon.svg" alt="download_button" />
                        )}
                    </button>
                    <button
                        style={{
                            display:
                                isExpanded ||
                                (question?.question_settings?.type ===
                                    QuestionTypeSettings.VOICE_OR_TEXT &&
                                    !playNodeio.media?.url) ||
                                (question?.question_settings.type !==
                                    QuestionTypeSettings.VOICE_OR_TEXT &&
                                    question?.question_settings.type !==
                                        QuestionTypeSettings.VOICE_ONLY)
                                    ? 'none'
                                    : 'block',
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setTimeout(() => {
                                player.current?.playAudioPromise()
                            }, 1000)
                            toggleExpand(playNodeio.id)
                        }}
                    >
                        <img src="/asset/play-button-icon.svg" alt="play_button" />
                    </button>
                    {/* {isExpanded && (
                        <div className="flex items-center gap-2">
                            <button>
                                <img src="/asset/lightbulb.svg" alt="lightbulb" />
                            </button>
                            <button className="mt-1.5">
                                <img src="/asset/launch.svg" alt="launch" />
                            </button>
                        </div>
                    )} */}
                </div>
            </div>
            {isExpanded && (
                <>
                    {/* <div className="px-6 pb-6 -mt-1">
                            <p className="text-sm text-[#001F40]">
                                {moment(new Date()).diff(playNodeio.created_at, 'hours') > 72
                                    ? moment(playNodeio.created_at).format('LL')
                                    : moment(playNodeio.created_at).fromNow()}
                            </p>
                            {playNodeio.media?.transcription_language &&
                                playNodeio.media?.transcription_language !== 'en' && (
                                    <p className="text-[#007AFF] text-sm pt-2">
                                        Recorded in{' '}
                                        {getLanguageName(playNodeio.media.transcription_language)}
                                    </p>
                                )}
                        </div> */}
                    {!(
                        (question?.question_settings?.type === QuestionTypeSettings.VOICE_OR_TEXT &&
                            !playNodeio.media?.url) ||
                        (question?.question_settings.type !== QuestionTypeSettings.VOICE_OR_TEXT &&
                            question?.question_settings.type !== QuestionTypeSettings.VOICE_ONLY)
                    ) && (
                        <div className="px-6 pb-3.5">
                            <Player
                                ref={player}
                                url={playNodeio.media?.url ?? ''}
                                autoPlay={false}
                                onPlayError={() => {
                                    if (playNodeio.media?.url) {
                                        reloadAudio(playNodeio, (data) => {
                                            setPlayNodeio(data)
                                            if (data.media?.transcription_json) {
                                                setFormattedTranscriptionText(
                                                    getFormattedTranscriptionText(
                                                        data.media?.transcription_json,
                                                    ),
                                                )
                                            }
                                            if (data?.created_at) {
                                                setNoTranscriptionText(
                                                    getNoTranscriptionText(data.created_at),
                                                )
                                            }
                                            player.current?.playAudioPromise()
                                        })
                                    }
                                }}
                            />
                        </div>
                    )}
                    <div className="text-sm text-[#001F40] px-6 pt-1 pb-3.5 max-h-96 overflow-y-scroll">
                        {formattedTranscriptionText ??
                        playNodeio.media?.translated_transcription_text ??
                        playNodeio.media?.transcription_text ??
                        playNodeio.title ? (
                            <p className="whitespace-pre-line border-[rgba(182,_214,_250,_0.35)] border-solid border-l-[3px] py-1 pl-2">
                                {formattedTranscriptionText ??
                                    playNodeio.media?.translated_transcription_text ??
                                    playNodeio.media?.transcription_text ??
                                    playNodeio.title}
                            </p>
                        ) : playNodeio.title ? (
                            question?.question_settings?.type ===
                                QuestionTypeSettings.MULTI_SELECT &&
                            isJsonString(playNodeio?.title) ? (
                                JSON.parse(playNodeio?.title)?.map(
                                    (option: string, index: number) => (
                                        <li key={index}>{option}</li>
                                    ),
                                )
                            ) : (
                                playNodeio?.title
                            )
                        ) : (
                            <p>{noTranscriptionText}</p>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default RespondingNodeioCard
