import { CookieKey } from '../enums/CookieKey'
import {
    AskQuestionsPayload,
    Question,
    QuestionInsights,
    UpdateMVPsPayload,
} from '../types/Question'
import { QuestionNodeiosResponse } from '../types/QuestionNodeiosResponse'
import { getCookie } from '../utils/useCookies'
import ApiService from './apiService'
import axios, { AxiosResponse } from 'axios'

export interface QuestionApi {
    getById(id: string): Promise<AxiosResponse<Question>>
    deleteById(id: string): Promise<AxiosResponse<any>>
    getByIdOrSlug(idOrSlug: string): Promise<AxiosResponse<Question>>
    getBySlug(username: string, slug: string): Promise<AxiosResponse<Question>>
    getRespondingNodeiosByQuestionId(
        id: string,
        page: number,
        perPage: number,
    ): Promise<AxiosResponse<QuestionNodeiosResponse>>
    getInsightsByQuestionId(id: string): Promise<AxiosResponse<QuestionInsights>>
    getMVPsByQuestionId(id: string): Promise<AxiosResponse<any>>
    getQuestionsByIdOrSlug(
        id: string,
        withPreSignedUrl?: boolean,
    ): Promise<AxiosResponse<Question[]>>
    getQuestionsByIdOrSlugForLandingPage(
        id: string,
        withPreSignedUrl?: boolean,
    ): Promise<AxiosResponse<Question[]>>
    updateMVPsByQuestionId(id: string, mvps: UpdateMVPsPayload): Promise<AxiosResponse<any>>
    askQuestions(
        questions: AskQuestionsPayload[],
        slug: string,
        campaignId: string,
    ): Promise<AxiosResponse<Question[]>>
    patchQuestions(
        questions: AskQuestionsPayload[],
        slug: string,
    ): Promise<AxiosResponse<Question[]>>
    gernerateQuestionInsights(id: string): Promise<AxiosResponse<QuestionInsights>>
    downloadQuestion(slug: string): Promise<AxiosResponse<any>>
    downloadQuestionData(questionId: string): Promise<AxiosResponse<any>>
    downloadBulkAudio(slug: string): Promise<AxiosResponse<any>>
    deleteQuestionAssets(questionId: string, path: string): Promise<AxiosResponse<any>>
    downloadAudioFiles(id: string): Promise<AxiosResponse<any>>
}

export const QuestionApi: QuestionApi = Object.freeze({
    getById(id: string): Promise<AxiosResponse<Question>> {
        return ApiService.get(`/questions/web-app/${id}`)
    },
    deleteById(id: string): Promise<AxiosResponse<Question>> {
        return ApiService.delete(`/questions/${id}`)
    },
    getQuestionsByIdOrSlug(
        idOrSlug: string,
        withPreSignedUrl?: boolean,
    ): Promise<AxiosResponse<Question[]>> {
        if (withPreSignedUrl) {
            return ApiService.get(`/questions/web-app/${idOrSlug}?with_presigned_url=true`)
        }
        return ApiService.get(`/questions/web-app/${idOrSlug}`)
    },
    getQuestionsByIdOrSlugForLandingPage(
        idOrSlug: string,
        withPreSignedUrl?: boolean,
    ): Promise<AxiosResponse<Question[]>> {
        if (withPreSignedUrl) {
            return ApiService.get(
                `/questions/web-app/${idOrSlug}/landing-page?with_presigned_url=true`,
            )
        }
        return ApiService.get(`/questions/web-app/${idOrSlug}/landing-page`)
    },
    getByIdOrSlug(idOrSlug: string): Promise<AxiosResponse<Question>> {
        return ApiService.get(`/questions/web-app/${idOrSlug}`)
    },
    getBySlug(username: string, slug: string): Promise<AxiosResponse<Question>> {
        return ApiService.get(`/questions/${username}/${slug}`)
    },
    getRespondingNodeiosByQuestionId(
        id: string,
        page: number,
        perPage: number,
    ): Promise<AxiosResponse<QuestionNodeiosResponse>> {
        return ApiService.get(`/questions/${id}/nodeios?page=${page}&per_page=${perPage}`)
    },
    getInsightsByQuestionId(id: string): Promise<AxiosResponse<QuestionInsights>> {
        return ApiService.get(`/questions/${id}/insights`)
    },
    getMVPsByQuestionId(id: string): Promise<AxiosResponse<any>> {
        return ApiService.get(`/questions/${id}/mvps`)
    },
    updateMVPsByQuestionId(id: string, mvps: UpdateMVPsPayload): Promise<AxiosResponse<any>> {
        return ApiService.patch(`/questions/insights/mvps/${id}`, mvps)
    },
    askQuestions(
        questions: AskQuestionsPayload[],
        slug: string,
        campaignId: string,
    ): Promise<AxiosResponse<Question[]>> {
        const formData = new FormData()
        const questionsData = questions.map((question) => {
            return {
                question: question.question,
                campaign_id: question.campaignId,
                is_responding_nodeios_private: question.is_responding_nodeios_private,
                is_draft: question.is_draft,
                help_text: question.help_text,
                is_multi_question_link: question.is_multi_question_link,
                question_sequence: question.question_sequence,
                question_settings: question.question_settings,
                files: question.assets,
            }
        })
        formData.append('questions', JSON.stringify(questionsData))
        formData.append('campaign_id', campaignId)
        formData.append('slug', slug)
        for (const question of questions) {
            if (question.assets) {
                for (const asset of question.assets) {
                    formData.append('files', asset)
                }
            }
        }
        const baseURL =
            process.env.REACT_APP_ENV === 'production'
                ? process.env.REACT_APP_PRODUCTION_SERVER_URL
                : process.env.REACT_APP_TEST_SERVER_URL

        return axios.post(`${baseURL}questions/ask_questions`, formData, {
            headers: {
                Authorization: `Bearer ${getCookie(CookieKey.JWT_TOKEN)}`,
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    patchQuestions(questions: any[], slug: string): Promise<AxiosResponse<Question[]>> {
        const formData = new FormData()
        const questionsData = questions.map((question) => {
            return {
                id: question?.id ?? undefined,
                question: question.question,
                campaignId: question.campaignId,
                is_responding_nodeios_private: question.is_responding_nodeios_private,
                is_draft: question.is_draft,
                help_text: question.help_text,
                is_multi_question_link: question.is_multi_question_link,
                question_sequence: question.question_sequence,
                question_settings: question.question_settings,
                files: question.assets,
            }
        })
        formData.append('questions', JSON.stringify(questionsData))
        for (const question of questions) {
            if (question.assets) {
                for (const asset of question.assets) {
                    formData.append('files', asset)
                }
            }
        }

        const baseURL =
            process.env.REACT_APP_ENV === 'production'
                ? process.env.REACT_APP_PRODUCTION_SERVER_URL
                : process.env.REACT_APP_TEST_SERVER_URL
        return axios.patch(`${baseURL}questions/${slug}`, formData, {
            headers: {
                Authorization: `Bearer ${getCookie(CookieKey.JWT_TOKEN)}`,
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    gernerateQuestionInsights(questionId: string): Promise<AxiosResponse<QuestionInsights>> {
        return ApiService.post(
            `/questions/generate-insights`,
            {
                question_id: questionId,
            },
            {},
            600000,
        )
    },
    downloadQuestion(slug: string): Promise<AxiosResponse<any>> {
        return ApiService.download(`/questions/${slug}/download-report`)
    },
    downloadQuestionData(questionId: string): Promise<AxiosResponse<any>> {
        return ApiService.download(`/questions/${questionId}/download-report-for-single-question`)
    },
    downloadBulkAudio(slug: string): Promise<AxiosResponse<any>> {
        return ApiService.download(`/questions/${slug}/download-files`)
    },
    deleteQuestionAssets(questionId: string, path: string): Promise<AxiosResponse<any>> {
        return ApiService.delete(`/questions/${questionId}/assets`, {
            path: path,
        })
    },
    downloadAudioFiles(id: string): Promise<AxiosResponse<any>> {
        return ApiService.download(`/questions/${id}/download-soundbites`)
    },
})
