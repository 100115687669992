import { InterviewApi } from '../api/interviews'
import VoiceOnlyResponder from '../components/VoiceOnlyResponder'
import { Page } from '../enums/Page'
import { Interview } from '../types/Interview'
import { QuestionTypeSettings, QuestionTypesType } from '../types/QuestionType'
import { questionTypes } from '../utils/constant'
import { Tooltip } from 'flowbite-react'
// eslint-disable-next-line no-unused-vars, unused-imports/no-unused-imports
import { set } from 'lodash'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

export const EditInterviewCard = (): JSX.Element => {
    return (
        <div className="flex flex-col p-5 xs:p-10 h-full">
            <VoiceOnlyResponder disabled showNotes={false} />
        </div>
    )
}

const EditInterview = (): JSX.Element => {
    const { username, slug } = useParams<{ username: string; slug: string }>()
    const [interview, setInterview] = useState<Interview>()
    const [linkTitle, setLinkTitle] = useState<string>('')
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

    const navigate = useNavigate()

    const fetchInterview = async (): Promise<void> => {
        if (slug) {
            const interviewResponse = await InterviewApi.getInterviewBySlug(slug)
            setInterview(interviewResponse.data)
            setLinkTitle(interviewResponse.data?.title ?? '')
        }
    }

    useEffect(() => {
        fetchInterview()
    }, [slug])

    const isCreateButtonDisabled = linkTitle === ''

    return (
        <div className="flex-1 px-[25px] sm:px-[70px] lg:pr-[120px] pb-20">
            <div className="flex flex-col xs:flex-row justify-between gap-0 md:gap-10">
                <div className="flex items-center gap-6 flex-1">
                    <button
                        className="text-[#007AFF] text-base mr-3 disabled:text-[#007AFF80]"
                        disabled={isCreateButtonDisabled}
                        onClick={() =>
                            navigate(
                                generatePath(Page.INTERVIEW_LANDING_PAGE, {
                                    username: username as string,
                                    slug: slug as string,
                                }),
                            )
                        }
                    >
                        <img src="/asset/left-white.svg" className="w-[14px] h-[19px]" />
                    </button>
                    <input
                        className="text-[25px] font-bold text-[#BFDEFF] placeholder-[rgba(191,_222,_255,_0.33)] outline-none focus-within:outline-none bg-transparent flex-1 min-w-[180px] sm:min-w-fit"
                        placeholder="Give your link a title..."
                        value={interview?.title || ''}
                        onChange={(event: any) => {
                            setLinkTitle(event.target.value)
                        }}
                    />
                </div>
                {/* <h2 className="text-[25px] font-bold text-[#BFDEFF]">Edit Link</h2> */}
                <div className="flex flex-row gap-7 items-center mt-5 xs:mt-0 self-end xs:self-center">
                    {/* <button
                        className="text-[#007AFF] font-bold text-base"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </button> */}
                    <button
                        className={`font-bold text-base text-white ${
                            isSubmitted ? 'bg-[#AFAFAF]' : 'bg-[#007AFF]'
                        } w-[100px] xs:w-[120px] g-9 rounded-[15px]`}
                        disabled={isSubmitted}
                        onClick={async () => {
                            setIsSubmitted(true)
                            if (interview?.id) {
                                await InterviewApi.updateInterviewById(interview.id, linkTitle)
                                navigate(
                                    generatePath(Page.INTERVIEW_LANDING_PAGE, {
                                        username: username as string,
                                        slug: slug as string,
                                    }),
                                )
                            }
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="flex-1">
                <div
                    className="mt-12 px-6 py-3 flex flex-col sm:flex-row justify-between items-center rounded-2xl gap-1 sm:gap-7"
                    style={{
                        background:
                            'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
                    }}
                >
                    <p className="text-[#007AFF] ellipsis flex-1">
                        {`${window.location.origin}/${username}/${slug}`}
                    </p>
                </div>
            </div>

            <div className="mt-[40px] flex min-h-[650px] gap-6 flex-col md:flex-row">
                <div className="flex-1 flex flex-col">
                    <div className="flex items-center gap-2">
                        <p className="text-2xl font-bold text-white">
                            {
                                questionTypes.find(
                                    (item: QuestionTypesType) =>
                                        item.id === QuestionTypeSettings.VOICE_ONLY,
                                )?.title
                            }
                        </p>
                        <Tooltip
                            content={
                                questionTypes.find(
                                    (item: QuestionTypesType) =>
                                        item.id === QuestionTypeSettings.VOICE_ONLY,
                                )?.tooltip
                            }
                            className="text-base px-5 py-2 max-w-[300px]"
                            placement="right"
                            theme={{
                                base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-bold shadow-sm !bg-[#BFDEFF] !text-[#001F40]',
                                arrow: {
                                    base: 'absolute z-10 h-2 w-2 rotate-45 !bg-[#BFDEFF]',
                                },
                            }}
                        >
                            <img src="/asset/help-circle.svg" />
                        </Tooltip>
                    </div>
                    <div className="h-[1px] w-full mt-3 mb-6 bg-[#BFDEFF]" />
                    <div
                        className="rounded-2xl flex-1"
                        style={{
                            background:
                                'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
                        }}
                    >
                        <EditInterviewCard />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditInterview
