import { ClipsApi } from '../api/clips'
import { ClipContentType } from '../enums/ClipContentType'
import { Clip } from '../types/Clip'
import { Tooltip } from 'flowbite-react'
import moment from 'moment'
import { useState } from 'react'

interface ClipCardProps {
    clipData: Clip
    selectedClipId: string
    onClipSelected: () => void
    isPlaying: boolean
    onClipPlayingStopped: () => void
}

const ClipCard = ({
    clipData,
    isPlaying,
    onClipSelected,
    onClipPlayingStopped,
}: ClipCardProps): JSX.Element => {
    const [isSelected, setIsSelected] = useState<boolean>(clipData?.is_selected || false)
    const [isHovered, setHovered] = useState(false)
    const isMobile = window.innerWidth < 768

    const convertDurationToMinutesSeconds = (duration: number) => {
        const minutes = Math.floor(duration / 60)
        const seconds = Math.round(duration % 60)
        let minutesString = minutes.toString()
        let secondsString = seconds.toString()
        if (minutes < 10) {
            minutesString = `0${minutes}`
        }
        if (seconds < 10) {
            secondsString = `0${seconds}`
        }

        return `${minutesString}:${secondsString}s`
    }

    const updateIsSelectedState = async (value: boolean) => {
        setIsSelected(value)
        try {
            await ClipsApi.updateSelectedStatus(clipData?.id || clipData.id, value)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('updateIsSelectedState error: ', err)
        }
    }

    let tooltipContent = ''
    if (clipData?.source?.type === ClipContentType.SURVEY_RESPONSE) {
        tooltipContent = `This clip is from a survey response titled ${
            clipData?.source?.source_title
        } from ${clipData?.source?.author} recorder ${
            moment(new Date()).diff(clipData?.source?.created_at, 'hours') > 72
                ? `on ${moment(clipData?.source?.created_at).format('LL')}`
                : moment(clipData?.source?.created_at).fromNow()
        }`
    } else if (clipData?.source?.type === ClipContentType.CONVERSATION) {
        tooltipContent = `This clip is from a conversation titled ${
            clipData?.source?.source_title
        } with ${clipData?.source?.author} recorder ${
            moment(new Date()).diff(clipData?.source?.created_at, 'hours') > 72
                ? `on ${moment(clipData?.source?.created_at).format('LL')}`
                : moment(clipData?.source?.created_at).fromNow()
        }`
    }
    return (
        <div
            className="flex flex-row justify-between items-center"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className="flex flex-row w-[40%] justify-start items-center">
                {!isPlaying ? (
                    <button onClick={onClipSelected} className="p-2.5">
                        <img src="/asset/play-circle-outline.svg" />
                    </button>
                ) : (
                    <button onClick={onClipPlayingStopped} className="p-2.5">
                        <img src="/asset/playing-bars.svg" />
                    </button>
                )}
                <p
                    className={`text-sm 2xl:text-base ellipsis-1 break-all ${
                        isPlaying ? 'text-[#007AFF]' : 'text-black'
                    }`}
                >
                    {clipData?.title}
                </p>
            </div>
            <div className="flex w-[10%] justify-start items-center">
                <p className="text-[#001F40] text-sm 2xl:text-base text-black">
                    {convertDurationToMinutesSeconds(clipData?.duration || 0)}
                </p>
            </div>
            <div className="flex w-[10%] justify-start items-center">
                {clipData?.source && !isMobile && (
                    <Tooltip
                        content={tooltipContent}
                        className="text-sm p-5 py-2 max-w-[300px] text-black font-bold font-lato bg-[#f7f7f7]"
                        placement="right"
                        theme={{
                            base: 'absolute inline-block z-10 rounded-lg py-2 px-3 shadow-sm',
                            arrow: {
                                base: 'display-none',
                            },
                        }}
                    >
                        <p className="text-[#001F40] text-sm text-black rounded-[30px] bg-[#D9D9D969] py-2 px-4">
                            Source
                        </p>
                    </Tooltip>
                )}
            </div>
            <div className="flex flex-row w-[10%] gap-10 justify-start items-center">
                <p className="text-[#8092A5] text-sm">
                    {moment(new Date()).diff(clipData.created_at, 'hours') > 72
                        ? moment(clipData.created_at).format('LL')
                        : moment(clipData.created_at).fromNow()}
                </p>
            </div>
            <div className="flex flex-row w-[10%] justify-start items-center">
                {isSelected && (
                    <button
                        onClick={() => {
                            updateIsSelectedState(false)
                        }}
                        className="p-2.5"
                    >
                        <img src="/asset/check-blue-circle.svg" />
                    </button>
                )}
                {isMobile && !isSelected && (
                    <button
                        onClick={() => {
                            updateIsSelectedState(true)
                        }}
                        className="p-2.5"
                    >
                        <img src="/asset/add-gray-circle-outline.svg" />
                    </button>
                )}
                {!isSelected && isHovered && !isMobile && (
                    <button
                        onClick={() => {
                            updateIsSelectedState(true)
                        }}
                        className="p-2.5"
                    >
                        <img src="/asset/add-gray-circle-outline.svg" />
                    </button>
                )}
            </div>
        </div>
    )
}

export default ClipCard
