import MultipleSelectResponder from '../components/MultipleSelectResponder'
import NextQuestionButton from '../components/NextQuestionButton'
import PreviousQuestionButton from '../components/PreviousQuestionButton'
import QuestionAssets from '../components/QuestionAssets'
import SingleSelectResponder from '../components/SingleSelectResponder'
import TextWithLinks from '../components/TextWithLinks'
import VoiceOnlyResponder from '../components/VoiceOnlyResponder'
import VoiceTextResponder from '../components/VoiceTextResponder'
import { useQuestionStore } from '../context/question/store'
import { useUserStore } from '../context/user/store'
import { Mandatory } from '../enums/Mandatory'
import { Page } from '../enums/Page'
import { Question } from '../types/Question'
import { QuestionTypeSettings } from '../types/QuestionType'
import { defaultBrandBgColor } from '../utils/constant'
import { ResponseNote } from './QuestionResponder'
import React, { useState } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

const PreviewQuestions = (): JSX.Element => {
    const {
        state: { questions },
    } = useQuestionStore()
    const {
        state: { user },
    } = useUserStore()
    const { state } = useLocation()

    const [questionIndex, setQuestionIndex] = useState<number>(state?.questionIndex ?? 0)

    const selectedQuestion: Question | null =
        questions && questions.length ? questions[questionIndex] ?? null : null
    const [responseNotes, setResponseNotes] = useState<ResponseNote[]>([])

    React.useEffect(() => {
        setResponseNotes(
            questions.map((question: Question) => ({
                questionId: question.id,
                note: '',
                open: false,
                title:
                    question.question_settings.type === QuestionTypeSettings.MULTI_SELECT
                        ? []
                        : undefined,
            })),
        )
    }, [])

    const responseNoteForSelectedQuestion: ResponseNote | undefined = responseNotes.find(
        (response) => response.questionId === selectedQuestion?.id,
    )

    const navigate = useNavigate()
    return (
        <div className="w-full h-screen relative flex flex-col justify-center items-center web-responder">
            <div
                className="relative flex justify-between w-full pb-[50px] px-[25px] sm:px-[50px] md:px-[100px] lg:px-[200px] h-[250px] bg-[#CBDBED] items-center"
                style={{
                    background: user?.brand?.background_color || defaultBrandBgColor,
                }}
            >
                {user?.brand?.background_image_url && (
                    <img
                        src={user?.brand?.background_image_url}
                        className="w-full h-full absolute object-cover mb-[-50px] mx-[-25px] sm:mx-[-50px] md:mx-[-100px] lg:mx-[-200px] z-0"
                    />
                )}
            </div>
            <div className="flex flex-1 bg-white"></div>
            <div className="top-[50px] xs:top-[70px] sm:top-[100px] absolute z-20 flex flex-col items-center h-screen w-screen md:w-fit bg-transparent pb-10 px-[25px] md:px-[0px] overflow-auto xs:overflow-visible">
                <div className="mb-[40px] flex justify-between w-full lg:w-[calc(100%_+_120px)]">
                    <div
                        className="flex h-[48px] w-fit xs:w-[200px] z-10 p-2 xs:p-3 bg-white rounded-full items-center"
                        onClick={() =>
                            navigate(
                                generatePath(Page.PROFILE_EDIT, {
                                    id: user.username,
                                }),
                            )
                        }
                    >
                        <img
                            src="/asset/brush_alt.svg"
                            alt="brush_alt"
                            className="w-[18px] h-[18px] mx-2"
                        />
                        <a className="text-base font-bold text-[#007AFF] cursor-pointer ml-1 hidden xs:block">
                            Brand this section
                        </a>
                    </div>
                    <div
                        className="flex h-[48px] w-fit xs:w-[150px] z-10 p-2 xs:p-3 bg-white rounded-full items-center justify-center"
                        onClick={
                            () => navigate(-1)
                            // navigate(
                            //     generatePath(Page.QUESTION_CREATE, {
                            //         username: user.username,
                            //     }),
                            //     {
                            //         state: {
                            //             questionIndex,
                            //         },
                            //     },
                            // )
                        }
                    >
                        <img
                            src="/asset/clear.svg"
                            alt="clear"
                            className="w-[20px] h-[20px] mx-2 block xs:hidden"
                        />
                        <button className="text-[#007AFF] text-base font-lato font-bold hidden xs:block">
                            Exit Preview
                        </button>
                    </div>
                </div>
                <div
                    className="w-full md:w-[720px] pl-[45px] pr-4 pt-10 pb-4 rounded-lg"
                    style={{
                        background:
                            'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                    }}
                >
                    <React.Fragment>
                        <div className="flex flex-row items-center gap-4">
                            <img
                                src={
                                    user.profile_photo?.url ??
                                    'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                }
                                width={40}
                                height={40}
                                className="z-10 w-10 h-10 rounded-full"
                                alt="profile_photo"
                            />
                            <div className="flex flex-col justify-center">
                                <p className="text-base text-[#BFDEFF]">{user?.first_name} </p>
                                <p className="text-[13px] text-[#BFDEFF] font-lato">{user?.bio}</p>
                            </div>
                        </div>
                        <div
                            className={`flex flex-col gap-2 mt-[26px] ${
                                questions.length > 1 ? 'mb-0' : 'mb-4'
                            }`}
                        >
                            <TextWithLinks
                                className="text-2xl text-white font-lato whitespace-pre-wrap overflow-x-auto"
                                linkClassName="text-[#007AFF]"
                                text={
                                    selectedQuestion?.question ||
                                    selectedQuestion?.question_settings?.title ||
                                    ''
                                }
                            ></TextWithLinks>
                            <TextWithLinks
                                className="text-base text-[#BFDEFF] font-lato whitespace-pre-wrap overflow-x-auto"
                                linkClassName="text-[#007AFF]"
                                text={selectedQuestion?.help_text || ''}
                            ></TextWithLinks>
                        </div>
                        {selectedQuestion?.assets && selectedQuestion?.assets.length > 0 && (
                            <QuestionAssets
                                key={selectedQuestion?.id}
                                className="max-h-[240px]"
                                assets={selectedQuestion?.assets?.map((asset: any) => {
                                    return {
                                        path: URL.createObjectURL(asset),
                                        name: asset.name,
                                        type: asset.type?.startsWith('image') ? 'image' : 'video',
                                    }
                                })}
                            />
                        )}
                        <div
                            className={`${
                                questions.length > 1 ? 'block' : 'hidden'
                            } flex flex-row gap-2 justify-end items-center mt-[22px]`}
                        >
                            {selectedQuestion?.question_settings?.require_question_response ===
                                Mandatory.REQUIRED && (
                                <p className="text-[13px] text-[#BFDEFF] font-bold mr-1">
                                    Required
                                </p>
                            )}
                            <p className="text-[#DBDDE0] text-sm">
                                {/* convert to 1 based index */}
                                {questionIndex + 1} / {questions.length}
                            </p>
                            <div className="flex flex-row gap-3">
                                <PreviousQuestionButton
                                    onClick={() => setQuestionIndex(questionIndex - 1)}
                                    disabled={questionIndex === 0}
                                />
                                <NextQuestionButton
                                    onClick={() => setQuestionIndex(questionIndex + 1)}
                                    disabled={questionIndex === questions!.length - 1}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                </div>
                <div className="w-full flex items-center justify-center pb-[50px]">
                    {selectedQuestion?.question_settings.type ===
                        QuestionTypeSettings.VOICE_ONLY && <VoiceOnlyResponder disabled />}

                    {selectedQuestion?.question_settings.type ===
                        QuestionTypeSettings.VOICE_OR_TEXT && <VoiceTextResponder disabled />}

                    {selectedQuestion?.question_settings.type ===
                        QuestionTypeSettings.SINGLE_SELECT && (
                        <SingleSelectResponder
                            isQuestionResponder
                            selectedQuestion={selectedQuestion}
                            setResponseNotes={setResponseNotes}
                            responseNoteForSelectedQuestion={responseNoteForSelectedQuestion}
                        />
                    )}

                    {selectedQuestion?.question_settings.type ===
                        QuestionTypeSettings.MULTI_SELECT && (
                        <MultipleSelectResponder
                            isQuestionResponder
                            selectedQuestion={selectedQuestion}
                            setResponseNotes={setResponseNotes}
                            responseNoteForSelectedQuestion={responseNoteForSelectedQuestion}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
export default PreviewQuestions
