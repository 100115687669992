export enum Page {
    SIGN_IN = '/signin',
    SIGN_UP = '/signup',
    HOME = '/',
    LINK_EXPIRED = '/link-expired',
    SELECT_PROFILE = '/select-profile',
    PROFILES = '/profiles',
    PROFILE = '/profiles/:id',
    PROFILE_EDIT = '/profiles/:id/edit',
    USERS = '/users',
    SETTINGS = '/settings',
    CAMPAIGN_LANDING_PAGE = '/:username/campaign-landing/:id',
    QUESTION_CREATE = '/:username/create/:campaignId',
    QUESTION_PREVIEW = '/:username/preview',
    QUESTION_LANDING_PAGE = '/:username/:slug/landing',
    QUESTION_LANDING_PAGE_MORE = '/:username/:slug/responses/:id',
    EDIT_AUDIO = '/:username/:slug/audio/:id',
    SUCCESSFULLY_SAVED_RESPONSES = '/successfully-saved-responses',
    QUESTION_RESPONDER = '/:username/:idOrSlug',
    INTERVIEW_LANDING_PAGE = '/:username/:slug/live-landing',
    INTERVIEW_RESPONSE_LANDING_PAGE = '/interview-responses/:id',
    INTERVIEW_RESPONDER = '/:username/interview/:slug',
    STORYBOARD = '/mvps/:id',
    EDIT_QUESTIONS = '/:username/:slug/edit',
    EDIT_INTERVIEW = '/:username/:slug/edit-live',
    QUESTION_RESPONDER_OLD_URL = '/questions/:idOrSlug',
    PUBLICITY_RELEASE = '/publicity-release',
    PRIVACY = '/privacy',
    TERMS = '/terms',
    THANK_YOU_FANS = '/ThankYouFans',
    CREATE_REEL = '/reels/:campaignId/:topic/create',
}
