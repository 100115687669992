import { useUserStore } from '../context/user/store'
import { Clip } from '../types/Clip'
import { toMMHH } from '../utils/toMMHH'
import Spinner from './Spinner'
import { useCallback, useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import Regions from 'wavesurfer.js/dist/plugins/regions'
import Timeline from 'wavesurfer.js/dist/plugins/timeline'

interface TrimClipModalProps {
    clip: Clip
    onClose: () => void
    onSave(start: number, end: number): void
}

const TrimClipModal = ({ clip, onClose, onSave }: TrimClipModalProps): JSX.Element => {
    const {
        state: { loading },
        setLoading,
    } = useUserStore()

    const [wavesurfer, setWavesurfer] = useState<any>(null)
    const [trimmed, setTrimmed] = useState<any>({ start: 0, end: 0 })
    const [isPlaying, setIsPlaying] = useState(false)
    const [time, setTime] = useState<number>(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [isEdited, setIsEdited] = useState(false)

    const containerRef = useRef<HTMLDivElement>(null)

    const onPlayClick = useCallback(() => {
        wavesurfer.setTime(time)
        wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer, time])

    const updateTime = (e: any) => {
        setTime(e.target.value)
    }

    useEffect(() => {
        if (!containerRef.current) return

        const ws = WaveSurfer.create({
            height: 200,
            waveColor: '#007AFF',
            progressColor: 'rgb(100, 0, 100)',
            barWidth: 2,
            barGap: 2,
            barRadius: 2,
            barHeight: 1.2,
            cursorWidth: 2,
            url: clip.url,
            container: containerRef.current,
            backend: 'WebAudio',
        })

        ws.registerPlugin(Timeline.create())
        const wsRegions = ws.registerPlugin(Regions.create())

        ws.on('decode', () => {
            wsRegions.enableDragSelection({})
            setTrimmed((trimmed: any) => ({ ...trimmed, end: ws.getDuration() }))
        })

        wsRegions.on('region-clicked', (region, e) => {
            e.stopPropagation()
            region.play()
        })

        wsRegions.on('region-updated', (region) => {
            setIsEdited(true)
            setTrimmed({ start: region.start, end: region.end })
        })

        wsRegions.on('region-created', (region) => {
            setIsEdited(true)
            setTrimmed({ start: region.start, end: region.end })
        })

        setWavesurfer(ws)

        return () => {
            ws.destroy()
        }
    }, [containerRef])

    useEffect(() => {
        if (!wavesurfer) return

        setCurrentTime(0)
        setIsPlaying(false)

        const subscriptions = [
            wavesurfer.on('play', () => setIsPlaying(true)),
            wavesurfer.on('pause', () => setIsPlaying(false)),
            wavesurfer.on('timeupdate', (currentTime: any) => setCurrentTime(currentTime)),
            wavesurfer.on('ready', () => {
                setLoading(false)
            }),
        ]

        return () => {
            subscriptions.forEach((unsub) => unsub())
        }
    }, [wavesurfer])

    useEffect(() => {
        setTime(currentTime)
    }, [currentTime])

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-white flex flex-col justify-center items-center z-[99999]">
            {loading && <Spinner />}
            <div className="flex-1 mx-3 xs:mx-6 lg:mx-12 xl:mx-32 pt-20 pb-8">
                <div className="flex flex-col sm:flex-row justify-between sm:items-center px-2 gap-5">
                    <div className="flex flex-col xs:flex-row xs:items-center gap-3 text-base text-[#0C1B2C]">
                        <p>Audio Length: {toMMHH(wavesurfer?.getDuration() || 0)}</p>
                        <div className="w-[210px] xs:w-[1px] h-[1px] xs:h-[32px] bg-[#0C1B2C]" />
                        <p className="font-semibold">
                            Trimmed Audio Length: {toMMHH(trimmed.end - trimmed.start || 0)}
                        </p>
                    </div>
                </div>

                <div
                    ref={containerRef}
                    className="min-h-[200px] mb-12 mt-6 sm:mt-10"
                    id="waveform"
                ></div>

                <div className="flex flex-col xs:flex-row xs:items-center justify-between gap-5">
                    <div className="flex items-center gap-3">
                        <button
                            className={`rounded-full w-[35px] h-[35px] flex items-center justify-center ${
                                isPlaying ? 'bg-[#D92D20]' : 'bg-[#1570EF]'
                            }`}
                            onClick={onPlayClick}
                        >
                            <img
                                className={`mt-0.5 ${isPlaying ? '' : 'ml-0.5'}`}
                                src={
                                    isPlaying ? '/asset/player_end.svg' : '/asset/player_start.svg'
                                }
                            />
                        </button>
                        <input
                            onChange={updateTime}
                            onKeyDown={(e) => e.code === 'Enter' && onPlayClick()}
                            type="number"
                            value={time}
                            className="py-2 px-3 max-w-[135px] text-[#667085] border-[#D0D5DD] border rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] outline-none"
                        />
                    </div>
                    <div className="flex items-center gap-3 self-end">
                        <button
                            className="text-[#007AFF] text-sm font-semibold flex items-center p-1.5 px-4 rounded-3xl border-[#007AFF] border-2"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white text-sm flex items-center p-1.5 px-4 rounded-2xl border-2 border-[#007AFF] bg-[#007AFF]"
                            onClick={() => {
                                if (!isEdited) {
                                    onClose()
                                } else {
                                    onSave(trimmed.start, trimmed.end)
                                }
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrimClipModal
