import { InterviewApi } from '../api/interviews'
import Spinner from '../components/Spinner'
import { Page } from '../enums/Page'
import { InterviewResponse } from '../types/InterviewResponse'
import { timeTodayDateElseWithTime } from '../utils/dateTimeFormatter'
import {
    getFormattedTranscriptionText,
    getNoTranscriptionText,
} from '../utils/formatTranscriptionText'
import React, { useEffect, useRef, useState } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'

const InterviewResponseLandingPage = (): JSX.Element => {
    const [interviewResponse, setInterviewResponse] = useState<InterviewResponse | null>(null)
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const { state } = useLocation()
    const campaignId = state?.campaignId
    const audioRef = useRef<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [transcriptCopied, setTranscriptCopied] = useState<boolean>(false)
    const [formattedTranscriptionText, setFormattedTranscriptionText] = useState<string | null>('')
    const [noTranscriptionText, setNoTranscriptionText] = useState<string | null>('')

    const conversationMembers = interviewResponse?.responders.filter((responder) => {
        if (responder.first_name || responder.last_name || responder.email) {
            return true
        }
    })

    useEffect(() => {
        const fetchInterviewResponse = async () => {
            if (!id) return
            try {
                setLoading(true)
                const response = await InterviewApi.getInterviewResponseById(id)
                const { data } = await response
                setInterviewResponse(data)
                if (!id) return
                if (data.media?.transcription_json) {
                    setFormattedTranscriptionText(
                        getFormattedTranscriptionText(data.media.transcription_json),
                    )
                }
                if (data?.created_at) {
                    setNoTranscriptionText(getNoTranscriptionText(data.created_at))
                }
            } finally {
                setLoading(false)
            }
        }
        fetchInterviewResponse()
    }, [id])

    useEffect(() => {
        if (transcriptCopied) {
            const timeout = setTimeout(() => {
                setTranscriptCopied(false)
            }, 1000)
            return () => clearTimeout(timeout)
        }
    }, [transcriptCopied])

    let title = ''
    if (interviewResponse?.title) {
        title = interviewResponse.title
    } else if (interviewResponse?.interview?.title) {
        title = `RE: ${interviewResponse.interview.title}`
    }

    const copyTranscriptToClipboard = (): void => {
        navigator.clipboard.writeText(
            formattedTranscriptionText ||
                interviewResponse?.media?.translated_transcription_text ||
                interviewResponse?.media?.transcription_text ||
                '',
        )
        setTranscriptCopied(true)
    }

    return (
        <div className="flex-1 px-[20px] sm:px-[40px] lg:pr-[120px] pb-10">
            <div>{loading && <Spinner />}</div>
            <div className="flex items-center justify-between h-[38px]">
                <button
                    className="text-[#007AFF] text-base mr-4 disabled:text-[#007AFF80]"
                    onClick={() => {
                        if (interviewResponse?.interview) {
                            navigate(
                                generatePath(Page.INTERVIEW_LANDING_PAGE, {
                                    username: interviewResponse.interview?.author?.username,
                                    slug: interviewResponse.interview?.slug,
                                }),
                                {
                                    state: {
                                        campaignId: campaignId,
                                    },
                                },
                            )
                        }
                    }}
                >
                    <img src="/asset/left-gray.svg" className="w-[14px] h-[19px]" />
                </button>
                <h2 className="text-[25px] flex-1 font-bold text-[#8092A5] ellipsis-1 text-left pl-6 pr-12">
                    {interviewResponse?.interview?.title} &nbsp;&nbsp;/&nbsp;&nbsp; {title}
                </h2>
            </div>
            {/* <div
                className="rounded-md pl-6 py-10 mt-[65px]"
                style={{
                    background:
                        'var(--Card, linear-gradient(312deg, rgba(107, 178, 255, 0.19) 9.85%, rgba(0, 122, 255, 0.13) 35.98%, rgba(107, 178, 255, 0.13) 70.39%, rgba(0, 122, 255, 0.19) 95.04%), #0D0D0D)',
                    boxShadow: '0px 2px 10px 0px rgba(0, 31, 64, 0.10)',
                }}
            >
                <h1 className="text-[#BFDEFF] text-4xl font-bold">{title}</h1>
            </div> */}
            <div className="flex flex-row justify-between mt-6">
                {interviewResponse?.created_at && (
                    <p className="text-[#007AFF80] text-sm">
                        Recorded {timeTodayDateElseWithTime(new Date(interviewResponse.created_at))}
                    </p>
                )}
            </div>

            {conversationMembers && conversationMembers?.length > 0 && (
                <div className="bg-[#6bb2ff33] rounded-lg pt-4 pb-8 px-5 xs:px-10 mt-10">
                    <div className="flex flex-col xl:flex-col mt-1">
                        <div className="flex flex-col flex-1 mt-1">
                            <p className="text-[#007AFF] text-base font-bold font-lato pr-4 mb-4">
                                Conversation members
                            </p>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <ol>
                                {conversationMembers?.map((responder, index) => {
                                    return (
                                        <li key={index}>
                                            <p className="text-base font-lato font-bold text-black">
                                                {`Responder: ${responder?.first_name ?? ''} ${
                                                    responder?.last_name ?? ''
                                                } ${responder?.email ?? ''}`}
                                            </p>
                                        </li>
                                    )
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
            )}

            <div className={`h-10 mt-10`} id="interview-responder-landing-page-player">
                <AudioPlayer
                    className="bg-transparent p-0 shadow-none text-black text-sm font-lato italic"
                    ref={audioRef}
                    src={interviewResponse?.media?.url}
                    customProgressBarSection={[
                        RHAP_UI.MAIN_CONTROLS,
                        RHAP_UI.PROGRESS_BAR,
                        <div className="ml-4" />,
                        RHAP_UI.CURRENT_TIME,
                        <div>/</div>,
                        RHAP_UI.DURATION,
                    ]}
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    customControlsSection={[]}
                    customIcons={{
                        play: (
                            <img
                                src="/asset/play-button-icon.svg"
                                width={30}
                                height={30}
                                alt="play_button"
                            />
                        ),
                    }}
                    timeFormat="mm:ss"
                    loop={false}
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                />
            </div>
            <div className="flex flex-row justify-between mt-10">
                <p className="text-[#8092A5] text-base">Transcript</p>
                <div className="flex justify-end">
                    {transcriptCopied ? (
                        <p className="text-sm leading-[30px] duration-300 transition-colors text-[#007AFF] w-[130px] text-end">
                            Copied!
                        </p>
                    ) : (
                        <button
                            onClick={copyTranscriptToClipboard}
                            disabled={transcriptCopied}
                            className="text-sm text-[#007AFF]"
                        >
                            Copy Transcript
                        </button>
                    )}
                </div>
            </div>

            <div className="flex flex-row justify-between bg-[#FFFFFF] rounded-xl px-10 py-5 mt-4 max-h-96 overflow-y-scroll">
                <p className="text-[#001f40] text-base font-lato">
                    {formattedTranscriptionText ??
                    interviewResponse?.media?.translated_transcription_text ??
                    interviewResponse?.media?.transcription_text ? (
                        <p className="whitespace-pre-line border-[rgba(182,_214,_250,_0.35)] border-solid border-l-[3px] py-1 pl-2">
                            {formattedTranscriptionText ||
                                interviewResponse?.media?.translated_transcription_text ||
                                interviewResponse?.media?.transcription_text ||
                                ''}
                        </p>
                    ) : (
                        <p>{noTranscriptionText}</p>
                    )}
                </p>
            </div>
        </div>
    )
}
export default InterviewResponseLandingPage
