import { InterviewApi } from '../api/interviews'
import { QuestionApi } from '../api/question'
import MultipleSelectResponder from '../components/MultipleSelectResponder'
import NextQuestionButton from '../components/NextQuestionButton'
import PreviousQuestionButton from '../components/PreviousQuestionButton'
import QuestionAssets from '../components/QuestionAssets'
import SingleSelectResponder from '../components/SingleSelectResponder'
import ToggleSwitch from '../components/ToggleSwitch'
import UploadQuestionAssets from '../components/UploadQuestionAssets'
import VoiceOnlyResponder from '../components/VoiceOnlyResponder'
import VoiceTextResponder from '../components/VoiceTextResponder'
import { useQuestionStore } from '../context/question/store'
import { useUserStore } from '../context/user/store'
import { CollectCampaignTypeOption, CollectNameOption } from '../enums/CollectNameOption'
import { Mandatory } from '../enums/Mandatory'
import { Page } from '../enums/Page'
import { Question } from '../types/Question'
import { QuestionTypeSettings, QuestionTypesType } from '../types/QuestionType'
import { questionTypes } from '../utils/constant'
import { generateSlug } from '../utils/generateSlug'
import { Tooltip } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import { v4 as uuid } from 'uuid'

interface CreateLinkQuestionCardProps {
    selectedIndex: number
    totalQuestions: number
    question?: Question
    questions?: Question[]
    campaignType: CollectCampaignTypeOption
    onQuestionChange: (question: string) => void
    onHelpTextChange: (help_text: string) => void
    onRemovePressed: () => void
    onQuestionIndexChange: (index: number) => void
    onIsRequiredChange: () => void
    onShowNotesChange: () => void
    setQuestions: (questions: Question[]) => void
    setShowUploadQuestionAssets: (show: boolean) => void
}

const CreateLinkQuestionCard = ({
    selectedIndex,
    totalQuestions,
    question,
    questions,
    campaignType,
    onQuestionChange,
    onHelpTextChange,
    onRemovePressed,
    onQuestionIndexChange,
    onIsRequiredChange,
    onShowNotesChange,
    setQuestions,
    setShowUploadQuestionAssets,
}: CreateLinkQuestionCardProps): JSX.Element => {
    const showNextQuestionButton = selectedIndex < totalQuestions - 1
    const showPreviousQuestionButton = selectedIndex > 0

    const [deleteModal, setDeleteModal] = useState<boolean>(false)

    const ref = useRef<any>(null)

    useEffect(() => {
        ref?.current?.focus()
    }, [selectedIndex])

    if (!question?.question_settings.type) return <></>
    const toggleModal = () => setDeleteModal((v) => !v)

    return (
        <div className="flex flex-col p-5 xs:p-10 h-full">
            {campaignType === CollectCampaignTypeOption.ASYNC && (
                <TextareaAutosize
                    ref={ref}
                    className="text-[#001F40] placeholder-[#8E8D93] text-[25px] bg-transparent focus:outline-none resize-none border-none mb-[10px] leading-[1.2] w-full"
                    placeholder="Start typing your question"
                    value={question?.question}
                    onChange={(e) => onQuestionChange(e.target.value)}
                />
            )}
            {campaignType === CollectCampaignTypeOption.ASYNC && (
                <TextareaAutosize
                    className="text-[#001F40] placeholder-[#8E8D93] text-md bg-transparent focus:outline-none resize-none border-none mb-[20px] leading-[1.2] w-full"
                    placeholder="Type some context to help respondents know why you are asking..."
                    value={question?.help_text}
                    onChange={(e) => onHelpTextChange(e.target.value)}
                />
            )}
            <div className="flex flex-row mb-4 w-full justify-center">
                {question?.assets && question?.assets?.length > 0 && (
                    <QuestionAssets
                        key={selectedIndex}
                        assets={question?.assets.map((asset: any) => {
                            return {
                                path: URL.createObjectURL(asset),
                                name: asset.name,
                                type: asset.type?.startsWith('image') ? 'image' : 'video',
                            }
                        })}
                    />
                )}
            </div>
            {question?.question_settings.type === QuestionTypeSettings.VOICE_ONLY && (
                <VoiceOnlyResponder
                    disabled
                    showNotes={!!question?.question_settings?.show_notes}
                />
            )}
            {question?.question_settings.type === QuestionTypeSettings.VOICE_OR_TEXT && (
                <VoiceTextResponder
                    disabled
                    showNotes={!!question?.question_settings?.show_notes}
                />
            )}

            {question?.question_settings.type === QuestionTypeSettings.SINGLE_SELECT && (
                <SingleSelectResponder
                    disabled
                    selectedQuestion={question}
                    setQuestions={setQuestions}
                    questionIndex={selectedIndex}
                    questions={questions}
                />
            )}

            {question?.question_settings.type === QuestionTypeSettings.MULTI_SELECT && (
                <MultipleSelectResponder
                    setQuestions={setQuestions}
                    questionIndex={selectedIndex}
                    questions={questions}
                    selectedQuestion={question}
                    disabled
                />
            )}

            <div className="flex-1"></div>
            {campaignType === CollectCampaignTypeOption.ASYNC && (
                <div className="flex flex-col xl:flex-row justify-between gap-5 mt-8 md:mt-0">
                    <div className="ml-[9px] flex flex-row xs:items-center gap-5 xs:gap-10">
                        <div className="flex flex-row xs:justify-end">
                            <button
                                className="text-[#007AFF] text-base font-bold rounded-2xl"
                                onClick={() => question && toggleModal()}
                            >
                                <div className="flex flex-row gap-1.5">
                                    <img src="/asset/trash_bin.svg" alt="trash_icon" />
                                </div>
                            </button>
                        </div>
                        <Tooltip content={<p>All questions are optional by default</p>}>
                            <ToggleSwitch
                                checked={
                                    question?.question_settings.require_question_response ===
                                    Mandatory.REQUIRED
                                }
                                label="Required"
                                onChange={onIsRequiredChange}
                            />
                        </Tooltip>
                        {(question.question_settings.type === QuestionTypeSettings.VOICE_ONLY ||
                            question.question_settings.type ===
                                QuestionTypeSettings.VOICE_OR_TEXT) && (
                            <Tooltip
                                content={
                                    <p>
                                        Let respondents draft thoughts before recording. For voice
                                        responses only.
                                    </p>
                                }
                            >
                                <ToggleSwitch
                                    checked={!!question?.question_settings.show_notes}
                                    label="Add Notes"
                                    onChange={onShowNotesChange}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            content={
                                <p>
                                    Add image(s) or video(s) to your question. User can see the
                                    image(s) or video(s) while answering the question.
                                </p>
                            }
                        >
                            <button
                                className="flex justify-center items-center py-2 px-4 gap-3 disabled:opacity-60"
                                onClick={() => {
                                    setShowUploadQuestionAssets(true)
                                }}
                            >
                                <img
                                    className="w-6 h-6"
                                    src="/asset/images.svg"
                                    alt="images_icon"
                                />
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex flex-row gap-10 justify-end">
                        <div className="flex flex-row items-center gap-2.5">
                            {totalQuestions > 1 && (
                                <p className="text-[#001F40] text-sm font-lato">
                                    {selectedIndex + 1}/{totalQuestions}
                                </p>
                            )}
                            <div className="flex flex-row gap-3">
                                <PreviousQuestionButton
                                    onClick={() => onQuestionIndexChange(selectedIndex - 1)}
                                    disabled={!showPreviousQuestionButton}
                                />
                                <NextQuestionButton
                                    onClick={() => onQuestionIndexChange(selectedIndex + 1)}
                                    disabled={!showNextQuestionButton}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                    deleteModal ? 'fixed' : 'hidden'
                }`}
            >
                <div
                    className="relative w-[350px] sm:w-[480px] bg-white px-[30px] p-[30px] py-[20px] rounded-2xl flex flex-col"
                    style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                >
                    <div className="flex-1">
                        <h2 className="text-[#007AFF] text-[25px] leading-10 pb-2">
                            Delete Question
                        </h2>
                        <p className="text-[#0D0D0D] text-[13px]">
                            Are you sure you want to delete "
                            {
                                questionTypes.find(
                                    (item: QuestionTypesType) =>
                                        item.id === question?.question_settings.type,
                                )?.title
                            }
                            " question?
                        </p>
                    </div>

                    <div className="flex self-end gap-7 mt-5">
                        <button
                            className="text-[#101828] text-base font-bold"
                            onClick={toggleModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white text-base font-bold flex bg-[var(--error-600,_#D92D20)] p-2 px-5 rounded-2xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]"
                            onClick={() => {
                                onRemovePressed()
                                toggleModal()
                            }}
                        >
                            Yes, Delete it
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ChangeOrderQuestionCardProps {
    question: string
    help_text: string
    onRemovePressed: () => void
}

export const ChangeOrderQuestionCard = ({
    question,
    help_text,
    onRemovePressed,
}: Partial<ChangeOrderQuestionCardProps>): JSX.Element => {
    return (
        <div
            className="flex flex-col justify-between gap-[2px] w-full rounded-2xl px-[6px] py-[10px] md:pl-[60px] md:pr-11 md:py-9"
            style={{
                background:
                    'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
            }}
        >
            <div className="flex flex-col justify-between gap-2">
                <p className="text-[#001F40] text-sm sm:text-[24px] bg-transparent font-lato">
                    {' '}
                    {question}
                </p>
                <p className="text-[#001F40] text-xs sm:text-sm bg-transparent font-lato">
                    {help_text}
                </p>
            </div>
            <div className="flex flex-row w-full justify-end">
                <button
                    className="text-[#007AFF] text-base font-bold rounded-2xl"
                    onClick={onRemovePressed}
                >
                    <div className="flex flex-row gap-1.5">
                        <img src="/asset/trash_bin.svg" alt="trash_icon" />
                        <p className="font-lato text-[#007AFF] font-bold text-base">Remove</p>
                    </div>
                </button>
            </div>
        </div>
    )
}

const CreateLink = (): JSX.Element => {
    const {
        state: { questions, tooltipHover, linkTitle },
        setQuestions,
        setLinkTitle,
    } = useQuestionStore()
    const { state } = useLocation()

    const { campaignId } = useParams<{ campaignId: string }>()
    const campaignType = state?.campaignType
    const [selectedIndex, setSelectedIndex] = useState<number>(
        questions.length > 0 ? 0 : state?.questionIndex ?? -1,
    )
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [isCollectNameEnabled, setIsCollectNameEnabled] = useState<boolean>(false)
    const [isCollectEmailEnabled, setIsCollectEmailEnabled] = useState<boolean>(false)
    const [collectCampaignTypeValue, setCollectCampaignTypeValue] =
        useState<CollectCampaignTypeOption>(CollectCampaignTypeOption.ASYNC)
    const [collectNameValue, setCollectNameValue] = useState<CollectNameOption>(
        CollectNameOption.OPTIONAL,
    )
    const [collectEmailValue, setCollectEmailValue] = useState<CollectNameOption>(
        CollectNameOption.OPTIONAL,
    )
    const selectedQuestion = questions[selectedIndex]

    const [openResponseTypesModal, setOpenResponseTypesModal] = useState<boolean>(false)

    const [showChangeOrder, setShowChangeOrder] = useState<boolean>(false)
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(true)
    const [showUploadQuestionAssets, setShowUploadQuestionAssets] = useState<boolean>(false)
    const [switchCampaignType, setSwitchCampaignType] = useState<boolean>(false)
    const navigate = useNavigate()
    const {
        state: { user },
    } = useUserStore()

    useEffect(() => {
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }, [shouldBlockNavigation])

    useEffect(() => {
        if (
            questions[0]?.question_settings?.require_email === Mandatory.OPTIONAL ||
            questions[0]?.question_settings?.require_email === Mandatory.REQUIRED
        ) {
            setCollectEmailValue(
                questions[0]?.question_settings?.require_email === Mandatory.OPTIONAL
                    ? CollectNameOption.OPTIONAL
                    : CollectNameOption.REQUIRED,
            )
            setIsCollectEmailEnabled(true)
        }

        if (
            questions[0]?.question_settings?.require_first_name === Mandatory.OPTIONAL ||
            questions[0]?.question_settings?.require_first_name === Mandatory.REQUIRED
        ) {
            setCollectNameValue(
                questions[0]?.question_settings?.require_first_name === Mandatory.OPTIONAL
                    ? CollectNameOption.OPTIONAL
                    : CollectNameOption.REQUIRED,
            )
            setIsCollectNameEnabled(true)
        }

        if (campaignType) {
            // eslint-disable-next-line no-console
            console.log('CreateLink - campaignType: ', campaignType)
            if (campaignType === 'live') {
                setQuestions([])
                setSelectedIndex(0)
                setQuestions([
                    {
                        id: uuid(),
                        question: '',
                        help_text: '',
                        question_sequence: 1,
                        question_settings: {
                            title: '',
                            campaign_type: collectCampaignTypeValue,
                            require_question_response: Mandatory.REQUIRED,
                            require_first_name: Mandatory.HIDDEN,
                            require_last_name: Mandatory.HIDDEN,
                            require_email: Mandatory.HIDDEN,
                            type: 'VOICE_ONLY',
                        },
                    },
                ])
                setCollectCampaignTypeValue(CollectCampaignTypeOption.LIVE)
            } else if (campaignType === 'async')
                setCollectCampaignTypeValue(CollectCampaignTypeOption.ASYNC)
        }
    }, [])

    const doAllQuestionsHaveTitle = questions.every((q: Question) => q.question.length > 0)

    const isCreateButtonDisabled: boolean =
        (questions.length === 0 || !doAllQuestionsHaveTitle || !linkTitle) &&
        !(collectCampaignTypeValue === CollectCampaignTypeOption.LIVE && linkTitle)

    const handelSubmit = async (): Promise<void> => {
        setIsSubmitted(true)
        setShouldBlockNavigation(false)
        if (collectCampaignTypeValue === CollectCampaignTypeOption.LIVE) {
            await InterviewApi.saveInterview(linkTitle, campaignId || '')
        } else {
            await QuestionApi.askQuestions(
                questions.map((q: Question, index: number) => {
                    return {
                        question: q.question,
                        campaignId: campaignId || '',
                        is_responding_nodeios_private: true,
                        is_draft: false,
                        help_text: q.help_text,
                        is_multi_question_link: questions.length > 1,
                        question_sequence: index + 1,
                        question_settings: {
                            ...q.question_settings,
                            /* eslint-disable indent */
                            require_first_name: !isCollectNameEnabled
                                ? Mandatory.HIDDEN
                                : collectNameValue === CollectNameOption.OPTIONAL
                                ? Mandatory.OPTIONAL
                                : Mandatory.REQUIRED,
                            /* eslint-disable indent */
                            require_last_name: !isCollectNameEnabled
                                ? Mandatory.HIDDEN
                                : collectNameValue === CollectNameOption.OPTIONAL
                                ? Mandatory.OPTIONAL
                                : Mandatory.REQUIRED,
                            /* eslint-disable indent */
                            require_email: !isCollectEmailEnabled
                                ? Mandatory.HIDDEN
                                : collectEmailValue === CollectNameOption.OPTIONAL
                                ? Mandatory.OPTIONAL
                                : Mandatory.REQUIRED,
                            title: linkTitle,
                            campaign_type: collectCampaignTypeValue,
                        },
                        assets: q.assets,
                    }
                }),
                generateSlug(questions[0].question, true),
                campaignId || '',
            )
        }
        setLinkTitle('')
        setQuestions([])
        setSelectedIndex(0)
        navigate(-1)
    }

    const toggleResponseTypeModal = () => setOpenResponseTypesModal((v) => !v)

    const moveQuestion = (index: number) => {
        setQuestions(
            questions.map((q: Partial<Question>, i: number) => {
                if (i === index) {
                    const question = questions[i + 1]
                    return {
                        ...question,
                        question_sequence: (question?.question_sequence as number) - 1,
                    }
                } else if (i === index + 1) {
                    const question = questions[i - 1]
                    return {
                        ...question,
                        question_sequence: (question?.question_sequence as number) + 1,
                    }
                } else {
                    return q
                }
            }),
        )
    }

    const onSwitchCampaignTypeToConversation = () => {
        setQuestions([])
        setSelectedIndex(0)
        setQuestions([
            {
                id: uuid(),
                question: '',
                help_text: '',
                question_sequence: 1,
                question_settings: {
                    title: '',
                    campaign_type: collectCampaignTypeValue,
                    require_question_response: Mandatory.REQUIRED,
                    require_first_name: Mandatory.HIDDEN,
                    require_last_name: Mandatory.HIDDEN,
                    require_email: Mandatory.HIDDEN,
                    type: 'VOICE_ONLY',
                },
            },
        ])
        setCollectCampaignTypeValue(CollectCampaignTypeOption.LIVE)
    }

    return (
        <div className="flex-1 px-[25px] sm:px-[70px] lg:pr-[120px] pb-20">
            {showUploadQuestionAssets && (
                <UploadQuestionAssets
                    onConfirm={(files: File[]) => {
                        setQuestions(
                            questions.map((q: Question, index: number) => {
                                if (index === selectedIndex) {
                                    return {
                                        ...q,
                                        assets: files,
                                    }
                                }
                                return q
                            }),
                        )
                        setShowUploadQuestionAssets(false)
                    }}
                    onClose={() => setShowUploadQuestionAssets(false)}
                />
            )}
            <div className="flex-1">
                <div className="flex flex-col xs:flex-row justify-between gap-7">
                    <div className="flex items-center gap-8 flex-1">
                        <button
                            className="text-[#007AFF] text-base disabled:text-[#007AFF80]"
                            onClick={() => {
                                setLinkTitle('')
                                setQuestions([])
                                setSelectedIndex(0)
                                navigate(-1)
                            }}
                        >
                            <img src="/asset/left-gray.svg" className="w-[14px] h-[19px]" />
                        </button>
                        <input
                            className="text-[25px] font-bold text-[#8092A5] placeholder-[rgba(191,_222,_255,_0.73)] outline-none focus-within:outline-none bg-transparent flex-1 min-w-[180px] sm:min-w-fit"
                            placeholder="Enter a title..."
                            value={linkTitle}
                            onChange={(event: any) => setLinkTitle(event.target.value)}
                        />
                    </div>
                    <div className="flex flex-row gap-6 items-center self-end sm:self-center">
                        <>
                            <div className="flex justify-center">
                                <button
                                    onClick={() => {
                                        setQuestions(
                                            questions.map((q: Question) => {
                                                return {
                                                    ...q,
                                                    question_settings: {
                                                        ...q.question_settings,
                                                        title: linkTitle,
                                                        campaign_type: collectCampaignTypeValue,
                                                        require_first_name: !isCollectNameEnabled
                                                            ? Mandatory.HIDDEN
                                                            : collectNameValue ===
                                                              CollectNameOption.OPTIONAL
                                                            ? Mandatory.OPTIONAL
                                                            : Mandatory.REQUIRED,
                                                        require_last_name: !isCollectNameEnabled
                                                            ? Mandatory.HIDDEN
                                                            : collectNameValue ===
                                                              CollectNameOption.OPTIONAL
                                                            ? Mandatory.OPTIONAL
                                                            : Mandatory.REQUIRED,
                                                        require_email: !isCollectEmailEnabled
                                                            ? Mandatory.HIDDEN
                                                            : collectEmailValue ===
                                                              CollectNameOption.OPTIONAL
                                                            ? Mandatory.OPTIONAL
                                                            : Mandatory.REQUIRED,
                                                    },
                                                }
                                            }),
                                        )
                                        navigate(
                                            generatePath(Page.QUESTION_PREVIEW, {
                                                username: user.username,
                                            }),
                                            {
                                                state: {
                                                    questionIndex: selectedIndex,
                                                },
                                            },
                                        )
                                    }}
                                >
                                    <img src="/asset/eye-icon.svg" alt="eye-icon" />
                                </button>
                                <span
                                    className={`absolute -top-10 scale-0 transition-all rounded bg-[#BFDEFF] py-1 px-2.5 font-bold text-base text-[#001F40] ${
                                        tooltipHover ? 'scale-100' : 'scale-0'
                                    }`}
                                >
                                    Preview
                                </span>
                            </div>
                            <button
                                className={`font-bold font-lato text-white text-base ${
                                    isCreateButtonDisabled || isSubmitted || showChangeOrder
                                        ? 'bg-[#AFAFAF]'
                                        : 'bg-[#007AFF]'
                                } w-[120px] g-9 rounded-[15px] py-1`}
                                disabled={isCreateButtonDisabled || isSubmitted || showChangeOrder}
                                onClick={handelSubmit}
                            >
                                Publish
                            </button>
                        </>
                    </div>
                </div>

                <div className="flex flex-col px-0 sm:px-6 mt-[10px]">
                    <div className="flex flex-col xs:flex-row items-start sm:items-center gap-5 xs:gap-3 xl:gap-8">
                        <p className="font-lato font-bold text-base text-[#007AFF] mt-6 mb-2">
                            Campaign Type:
                        </p>
                        <div className="flex flex-col xs:flex-row items-center gap-5 mt-6 mb-2">
                            <label className="flex flex-row items-center gap-1 text-[#007AFF] text-base font-lato">
                                <input
                                    className="w-6 h-6"
                                    type="radio"
                                    name="collect-campaign-type-option"
                                    value={CollectCampaignTypeOption.ASYNC}
                                    checked={
                                        collectCampaignTypeValue === CollectCampaignTypeOption.ASYNC
                                    }
                                    onChange={() => {
                                        setQuestions([])
                                        setSelectedIndex(0)
                                        setCollectCampaignTypeValue(CollectCampaignTypeOption.ASYNC)
                                    }}
                                />
                                SURVEY
                            </label>

                            {!showChangeOrder && (
                                <label className="flex flex-row items-center gap-1 text-[#007AFF] text-base font-lato">
                                    <input
                                        className="w-6 h-6"
                                        type="radio"
                                        name="collect-campaign-type-option"
                                        value={CollectCampaignTypeOption.LIVE}
                                        checked={
                                            collectCampaignTypeValue ===
                                            CollectCampaignTypeOption.LIVE
                                        }
                                        onChange={() => {
                                            setSwitchCampaignType(true)
                                        }}
                                    />
                                    CONVERSATION
                                </label>
                            )}
                            <Tooltip
                                content={
                                    <div>
                                        <p>
                                            A survey link is a set of questions that you can ask
                                            your audience. You can ask questions in different
                                            formats like text, voice, single select and multiple
                                            choice.
                                        </p>
                                        <br />
                                        <p>
                                            A conversation is a live interaction between you and
                                            your audience. You can create a link to record in voice
                                            only.
                                        </p>
                                    </div>
                                }
                                className="text-sm px-5 py-2 max-w-[300px]"
                                placement="right"
                                theme={{
                                    base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-bold shadow-sm !bg-[#BFDEFF] !text-[#001F40]',
                                    arrow: {
                                        base: 'absolute z-10 h-2 w-2 rotate-45 !bg-[#BFDEFF]',
                                    },
                                }}
                            >
                                <img src="/asset/help-circle.svg" />
                            </Tooltip>
                        </div>
                    </div>
                </div>

                {!showChangeOrder &&
                    collectCampaignTypeValue === CollectCampaignTypeOption.LIVE && (
                        <div className="flex-1 flex flex-col">
                            {selectedQuestion?.question_settings.type && (
                                <>
                                    <div className="flex items-center gap-2">
                                        <p className="text-2xl font-bold text-black">
                                            {
                                                questionTypes.find(
                                                    (item: QuestionTypesType) =>
                                                        item.id ===
                                                        selectedQuestion?.question_settings.type,
                                                )?.title
                                            }
                                        </p>
                                        <Tooltip
                                            content={
                                                questionTypes.find(
                                                    (item: QuestionTypesType) =>
                                                        item.id ===
                                                        selectedQuestion?.question_settings.type,
                                                )?.tooltip
                                            }
                                            className="text-base px-5 py-2 max-w-[300px]"
                                            placement="right"
                                            theme={{
                                                base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-bold shadow-sm !bg-[#BFDEFF] !text-[#001F40]',
                                                arrow: {
                                                    base: 'absolute z-10 h-2 w-2 rotate-45 !bg-[#BFDEFF]',
                                                },
                                            }}
                                        >
                                            <img src="/asset/help-circle.svg" />
                                        </Tooltip>
                                    </div>
                                    <div className="h-[1px] w-full mt-3 mb-6 bg-[#BFDEFF]" />
                                </>
                            )}
                            <div
                                className="rounded-2xl flex-1"
                                style={{
                                    background:
                                        'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
                                }}
                            >
                                <CreateLinkQuestionCard
                                    selectedIndex={selectedIndex}
                                    totalQuestions={questions.length}
                                    question={selectedQuestion}
                                    campaignType={collectCampaignTypeValue}
                                    onQuestionChange={(question) => {
                                        const newQuestions = [...questions]
                                        newQuestions[selectedIndex].question = question
                                        setQuestions(newQuestions)
                                    }}
                                    onHelpTextChange={(help_text) => {
                                        const newQuestions = [...questions]
                                        newQuestions[selectedIndex].help_text = help_text
                                        setQuestions(newQuestions)
                                    }}
                                    onRemovePressed={() => {
                                        if (questions.length === 1) {
                                            setQuestions([])
                                            setSelectedIndex(0)
                                        } else {
                                            setQuestions(
                                                questions.filter(
                                                    (q: Question, index: number) =>
                                                        index !== selectedIndex,
                                                ),
                                            )
                                            setSelectedIndex(selectedIndex - 1)
                                        }
                                    }}
                                    onQuestionIndexChange={(index) => {
                                        setSelectedIndex(index)
                                    }}
                                    onIsRequiredChange={() => {
                                        setQuestions(
                                            questions.map((q: Question, index: number) => {
                                                if (index === selectedIndex) {
                                                    return {
                                                        ...q,
                                                        question_settings: {
                                                            ...q.question_settings,
                                                            require_question_response:
                                                                q?.question_settings
                                                                    ?.require_question_response ===
                                                                Mandatory.REQUIRED
                                                                    ? Mandatory.OPTIONAL
                                                                    : Mandatory.REQUIRED,
                                                        },
                                                    }
                                                }
                                                return q
                                            }),
                                        )
                                    }}
                                    onShowNotesChange={() => {
                                        setQuestions(
                                            questions.map((q: Question, index: number) => {
                                                if (index === selectedIndex) {
                                                    return {
                                                        ...q,
                                                        question_settings: {
                                                            ...q.question_settings,
                                                            show_notes:
                                                                !q?.question_settings?.show_notes,
                                                        },
                                                    }
                                                }
                                                return q
                                            }),
                                        )
                                    }}
                                    setQuestions={setQuestions}
                                    questions={questions}
                                    setShowUploadQuestionAssets={setShowUploadQuestionAssets}
                                />
                            </div>
                        </div>
                    )}

                {collectCampaignTypeValue === CollectCampaignTypeOption.ASYNC && (
                    <div className="mt-[10px] flex min-h-[650px] gap-6 flex-col sm:flex-row">
                        <div className="w-full sm:w-[205px] min-w-full sm:min-w-[185px] rounded-2xl bg-white p-4 px-3 flex flex-col relative">
                            {!showChangeOrder && (
                                <div className="flex justify-between mx-1 py-3">
                                    <p className="text-[#0C1B2C] text-sm font-bold">
                                        Add question type
                                    </p>
                                    <button onClick={toggleResponseTypeModal}>
                                        <img src="/asset/plus_blue.svg" />
                                    </button>
                                </div>
                            )}
                            {!showChangeOrder && questions.length < 2 && (
                                <div className="flex justify-between mx-1 pt-2">
                                    <p className="text-[#0C1B2C] text-xs italic">
                                        Pro tip: Don't create a question that asks customers their
                                        name or email. Scroll down to enable these fields for this
                                        survey.
                                    </p>
                                </div>
                            )}
                            {showChangeOrder && (
                                <div className="flex justify-end mx-1 py-1">
                                    <button
                                        className="text-[#007AFF] font-lato font-bold text-sm sm:text-base p-2"
                                        onClick={() => setShowChangeOrder(false)}
                                    >
                                        Done
                                    </button>
                                </div>
                            )}
                            {openResponseTypesModal && (
                                <>
                                    <div className="z-10 rounded-xl bg-[var(--base-white,_#FFF)] grid p-4 gap-4 absolute -mr-5 top-[60px] md:top-[0] right-[30px] md:right-[unset] left-[unset] md:left-[calc(100%_+_10px)] grid-cols-[repeat(auto-fit,_minmax(150px,_1fr))] xs:w-[370px] shadow-[rgba(16,_24,_26,_0.3)_0px_4px_6px_-2px,_rgba(16,_24,_39,_0.8)_0px_12px_16px_2px] md:shadow-[0px_4px_6px_-2px_rgba(16,_24,_40,_0.03),_0px_12px_16px_-4px_rgba(16,_24,_40,_0.08)]">
                                        {questionTypes.map((item: any) => (
                                            <button
                                                key={item.id}
                                                className="flex items-center gap-3 p-2 rounded-md hover:bg-[var(--blue-100,_#D1E9FF)] cursor-pointer"
                                                onClick={() => {
                                                    setQuestions([
                                                        ...questions,
                                                        {
                                                            id: uuid(),
                                                            question: '',
                                                            help_text: '',
                                                            question_sequence: questions.length + 1,
                                                            question_settings: {
                                                                title: '',
                                                                require_question_response:
                                                                    Mandatory.OPTIONAL,
                                                                require_first_name:
                                                                    Mandatory.HIDDEN,
                                                                require_last_name: Mandatory.HIDDEN,
                                                                require_email: Mandatory.HIDDEN,
                                                                type: item.id,
                                                                options: [
                                                                    QuestionTypeSettings.SINGLE_SELECT,
                                                                    QuestionTypeSettings.MULTI_SELECT,
                                                                ].includes(item.id)
                                                                    ? ['']
                                                                    : undefined,
                                                            },
                                                        },
                                                    ])
                                                    setSelectedIndex(questions.length)
                                                    toggleResponseTypeModal()
                                                }}
                                            >
                                                <div
                                                    className="p-1.5 rounded-md w-fit"
                                                    style={{
                                                        background: item.color,
                                                    }}
                                                >
                                                    <img
                                                        src={item.icon}
                                                        className="w-[18px] h-[18px]"
                                                    />
                                                </div>
                                                <p className="text-[#0C1B2C] text-sm">
                                                    {item.title}
                                                </p>
                                            </button>
                                        ))}
                                    </div>
                                    <div
                                        className="top-0 left-0 w-full h-full fixed bg-transparent z-1"
                                        onClick={toggleResponseTypeModal}
                                    ></div>
                                </>
                            )}
                            <div className="flex-1 mt-8 mb-4 overflow-y-auto flex flex-col gap-3">
                                {questions.map((question: Question, index: number) => {
                                    const questionType = questionTypes.find(
                                        (_) => _.id === question.question_settings.type,
                                    )
                                    return (
                                        <div key={index}>
                                            <button
                                                key={question.id}
                                                className="flex items-center gap-2 p-2 pr-1 rounded-md w-full border-[1px] bg-white hover:bg-[var(--blue-100,_#D1E9FF)] border-[var(--gray-300,_#D0D5DD)] hover:border-[#007AFF] cursor-pointer"
                                                onClick={() => setSelectedIndex(index)}
                                                style={{
                                                    backgroundColor:
                                                        selectedIndex === index
                                                            ? 'var(--blue-100, #D1E9FF)'
                                                            : 'white',
                                                    borderColor:
                                                        selectedIndex === index
                                                            ? '#007AFF'
                                                            : 'var(--gray-300, #D0D5DD)',
                                                }}
                                            >
                                                <p>{index + 1}</p>
                                                <div
                                                    className="p-1.5 rounded-md w-fit"
                                                    style={{
                                                        background: questionType?.color,
                                                    }}
                                                >
                                                    <img
                                                        src={questionType?.icon}
                                                        className="w-[18px] h-[18px]"
                                                    />
                                                </div>
                                                <p className="text-[#0C1B2C] text-sm ellipsis-1 break-all">
                                                    {questionType?.title}
                                                </p>
                                            </button>
                                            {showChangeOrder && index < questions.length - 1 && (
                                                <button
                                                    onClick={() => moveQuestion(index)}
                                                    className="flex items-center justify-center"
                                                >
                                                    <img
                                                        src="/asset/swap_horiz.svg"
                                                        alt="swap_horiz"
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                            {/* <button
                                    className="mt-4 p-[8px] flex gap-3 items-center rounded-lg border-[1px] cursor-pointer bg-white hover:bg-[var(--blue-100,_#D1E9FF)] border-[var(--gray-300,_#D0D5DD)] hover:border-[#007AFF]"
                                    style={{
                                        backgroundColor:
                                            selectedIndex === -2 ? 'var(--blue-100, #D1E9FF)' : 'white',
                                        borderColor:
                                            selectedIndex === -2 ? '#007AFF' : 'var(--gray-300, #D0D5DD)',
                                    }}
                                    onClick={() => setSelectedIndex(-2)}
                                >
                                    <div className="p-1.5 bg-[var(--gray-900,_#101828)] rounded-md w-fit">
                                        <img src="/asset/trophy.svg" className="w-[18px] h-[18px]" />
                                    </div>
                                    <p className="text-[#0C1B2C] text-sm font-semibold">End Screen</p>
                                </button> */}
                        </div>
                        <div className="flex-1 flex flex-col">
                            {selectedQuestion?.question_settings.type && (
                                <>
                                    <div className="flex items-center gap-2">
                                        <p className="text-2xl font-bold text-black">
                                            {
                                                questionTypes.find(
                                                    (item: QuestionTypesType) =>
                                                        item.id ===
                                                        selectedQuestion?.question_settings.type,
                                                )?.title
                                            }
                                        </p>
                                        <Tooltip
                                            content={
                                                questionTypes.find(
                                                    (item: QuestionTypesType) =>
                                                        item.id ===
                                                        selectedQuestion?.question_settings.type,
                                                )?.tooltip
                                            }
                                            className="text-base px-5 py-2 max-w-[300px]"
                                            placement="right"
                                            theme={{
                                                base: 'absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-bold shadow-sm !bg-[#BFDEFF] !text-[#001F40]',
                                                arrow: {
                                                    base: 'absolute z-10 h-2 w-2 rotate-45 !bg-[#BFDEFF]',
                                                },
                                            }}
                                        >
                                            <img src="/asset/help-circle.svg" />
                                        </Tooltip>
                                    </div>
                                    <div className="h-[1px] w-full mt-3 mb-6 bg-[#BFDEFF]" />
                                </>
                            )}
                            <div
                                className="rounded-2xl flex-1"
                                style={{
                                    background:
                                        'var(--bg-for-questions, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #FFF)',
                                }}
                            >
                                <CreateLinkQuestionCard
                                    selectedIndex={selectedIndex}
                                    totalQuestions={questions.length}
                                    question={selectedQuestion}
                                    campaignType={collectCampaignTypeValue}
                                    onQuestionChange={(question) => {
                                        const newQuestions = [...questions]
                                        newQuestions[selectedIndex].question = question
                                        setQuestions(newQuestions)
                                    }}
                                    onHelpTextChange={(help_text) => {
                                        const newQuestions = [...questions]
                                        newQuestions[selectedIndex].help_text = help_text
                                        setQuestions(newQuestions)
                                    }}
                                    onRemovePressed={() => {
                                        if (questions.length === 1) {
                                            setQuestions([])
                                            setSelectedIndex(0)
                                        } else {
                                            setQuestions(
                                                questions.filter(
                                                    (q: Question, index: number) =>
                                                        index !== selectedIndex,
                                                ),
                                            )
                                            setSelectedIndex(selectedIndex - 1)
                                        }
                                    }}
                                    onQuestionIndexChange={(index) => {
                                        setSelectedIndex(index)
                                    }}
                                    onIsRequiredChange={() => {
                                        setQuestions(
                                            questions.map((q: Question, index: number) => {
                                                if (index === selectedIndex) {
                                                    return {
                                                        ...q,
                                                        question_settings: {
                                                            ...q.question_settings,
                                                            require_question_response:
                                                                q?.question_settings
                                                                    ?.require_question_response ===
                                                                Mandatory.REQUIRED
                                                                    ? Mandatory.OPTIONAL
                                                                    : Mandatory.REQUIRED,
                                                        },
                                                    }
                                                }
                                                return q
                                            }),
                                        )
                                    }}
                                    onShowNotesChange={() => {
                                        setQuestions(
                                            questions.map((q: Question, index: number) => {
                                                if (index === selectedIndex) {
                                                    return {
                                                        ...q,
                                                        question_settings: {
                                                            ...q.question_settings,
                                                            show_notes:
                                                                !q?.question_settings?.show_notes,
                                                        },
                                                    }
                                                }
                                                return q
                                            }),
                                        )
                                    }}
                                    setQuestions={setQuestions}
                                    questions={questions}
                                    setShowUploadQuestionAssets={setShowUploadQuestionAssets}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {/* {showChangeOrder && (
                    <div className="mt-[50px]">
                        {questions.map((q: Question, index: number) => {
                            return (
                                <div key={q.id}>
                                    <ChangeOrderQuestionCard
                                        question={q.question}
                                        help_text={q.help_text}
                                        onRemovePressed={() => {
                                            if (questions.length === 1) {
                                                setQuestions([])
                                                setSelectedIndex(0)
                                            } else {
                                                setQuestions(
                                                    questions.filter(
                                                        (qq: Question) => q.id !== qq.id,
                                                    ),
                                                )
                                                setSelectedIndex(selectedIndex - 1)
                                            }
                                        }}
                                    />
                                    {index !== questions.length - 1 && (
                                        <div className="w-full flex flex-row justify-start py-5 pl-10">
                                            <button
                                                onClick={() => {
                                                    setQuestions(
                                                        questions.map((q: Question, i: number) => {
                                                            if (i === index) {
                                                                const question = questions[i + 1]
                                                                return {
                                                                    ...question,
                                                                    question_sequence:
                                                                        question.question_sequence -
                                                                        1,
                                                                }
                                                            } else if (i === index + 1) {
                                                                const question = questions[i - 1]
                                                                return {
                                                                    ...question,
                                                                    question_sequence:
                                                                        question.question_sequence +
                                                                        1,
                                                                }
                                                            } else {
                                                                return q
                                                            }
                                                        }),
                                                    )
                                                }}
                                            >
                                                <img src="/asset/swap_horiz.svg" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )} */}
                {!showChangeOrder &&
                    collectCampaignTypeValue === CollectCampaignTypeOption.ASYNC && (
                        <div>
                            <div className="flex flex-col px-0 sm:px-6 pt-4">
                                <button
                                    onClick={() => setShowChangeOrder(true)}
                                    className="flex flex-row items-center gap-2"
                                    disabled={
                                        isSubmitted || showChangeOrder || questions.length <= 1
                                    }
                                >
                                    {isSubmitted || showChangeOrder || questions.length <= 1 ? (
                                        <img
                                            src="/asset/swap_horiz_disabled.svg"
                                            alt="swap_horiz_disabled"
                                        />
                                    ) : (
                                        <img src="/asset/swap_horiz.svg" alt="swap_horiz" />
                                    )}
                                    <p
                                        className={`font-bold font-lato text-base ${
                                            isSubmitted || showChangeOrder || questions.length <= 1
                                                ? 'text-[#AFAFAF]'
                                                : 'text-[#007AFF]'
                                        }`}
                                    >
                                        Change order
                                    </p>
                                </button>
                            </div>

                            <div className="flex flex-col px-0 sm:px-6">
                                <p className="font-lato font-bold text-base text-[#007AFF] mt-9 mb-6">
                                    Add as fields
                                </p>
                                <div className="flex flex-col xs:flex-row items-start sm:items-center gap-5 xs:gap-3 xl:gap-8">
                                    <ToggleSwitch
                                        checked={isCollectNameEnabled}
                                        label="Name"
                                        onChange={() =>
                                            setIsCollectNameEnabled(!isCollectNameEnabled)
                                        }
                                        theme={{
                                            root: {
                                                label: 'text-[#007AFF] font-lato text-base ml-2',
                                            },
                                        }}
                                    />
                                    {isCollectNameEnabled && (
                                        <div className="flex flex-col xs:flex-row items-center gap-5">
                                            <label className="flex flex-row items-center gap-1 text-[#000000] text-base font-lato">
                                                <input
                                                    className="w-6 h-6"
                                                    type="radio"
                                                    name="collect-name-option"
                                                    value={CollectNameOption.OPTIONAL}
                                                    checked={
                                                        collectNameValue ===
                                                        CollectNameOption.OPTIONAL
                                                    }
                                                    onChange={() =>
                                                        setCollectNameValue(
                                                            CollectNameOption.OPTIONAL,
                                                        )
                                                    }
                                                />
                                                {CollectNameOption.OPTIONAL}
                                            </label>

                                            <label className="flex flex-row items-center gap-1 text-[#000000] text-base font-lato">
                                                <input
                                                    className="w-6 h-6"
                                                    type="radio"
                                                    name="collect-name-option"
                                                    value={CollectNameOption.REQUIRED}
                                                    checked={
                                                        collectNameValue ===
                                                        CollectNameOption.REQUIRED
                                                    }
                                                    onChange={() =>
                                                        setCollectNameValue(
                                                            CollectNameOption.REQUIRED,
                                                        )
                                                    }
                                                />
                                                {CollectNameOption.REQUIRED}
                                            </label>
                                        </div>
                                    )}
                                </div>

                                <div className="flex flex-col xs:flex-row  mt-[30px] items-start sm:items-center gap-5 xs:gap-3 xl:gap-8">
                                    <ToggleSwitch
                                        checked={isCollectEmailEnabled}
                                        label="Email"
                                        onChange={() =>
                                            setIsCollectEmailEnabled(!isCollectEmailEnabled)
                                        }
                                        theme={{
                                            root: {
                                                label: 'text-[#007AFF] font-lato text-base ml-2',
                                            },
                                        }}
                                    />
                                    {isCollectEmailEnabled && (
                                        <div className="flex flex-col xs:flex-row items-center gap-5">
                                            <label className="flex flex-row items-center gap-1 text-[#000000] text-base font-lato">
                                                <input
                                                    className="w-6 h-6"
                                                    type="radio"
                                                    name="collect-email-option"
                                                    value={CollectNameOption.OPTIONAL}
                                                    checked={
                                                        collectEmailValue ===
                                                        CollectNameOption.OPTIONAL
                                                    }
                                                    onChange={() =>
                                                        setCollectEmailValue(
                                                            CollectNameOption.OPTIONAL,
                                                        )
                                                    }
                                                ></input>
                                                {CollectNameOption.OPTIONAL}
                                            </label>

                                            <label className="flex flex-row items-center gap-1 text-[#000000] text-base font-lato">
                                                <input
                                                    className="w-6 h-6"
                                                    type="radio"
                                                    name="collect-email-option"
                                                    value={CollectNameOption.REQUIRED}
                                                    checked={
                                                        collectEmailValue ===
                                                        CollectNameOption.REQUIRED
                                                    }
                                                    onChange={() =>
                                                        setCollectEmailValue(
                                                            CollectNameOption.REQUIRED,
                                                        )
                                                    }
                                                />
                                                {CollectNameOption.REQUIRED}
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            <div
                className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                    switchCampaignType ? 'fixed' : 'hidden'
                }`}
            >
                <div
                    className="relative w-[350px] sm:w-[480px] bg-white px-[30px] p-[30px] py-[20px] rounded-2xl flex flex-col"
                    style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                >
                    <div className="flex-1">
                        <h2 className="text-[#007AFF] text-[25px] leading-10 pb-2">
                            Switch Campaign Type
                        </h2>
                        <p className="text-[#0D0D0D] text-[13px]">
                            Are you sure you want to switch to CONVERSATION type? You will lose any
                            unsaved changes.
                        </p>
                    </div>

                    <div className="flex self-end gap-7 mt-5">
                        <button
                            className="text-[#101828] text-base font-bold"
                            onClick={() => {
                                setSwitchCampaignType(false)
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="text-white text-base font-bold flex bg-[#007AFF] p-2 px-5 rounded-2xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]"
                            onClick={() => {
                                onSwitchCampaignTypeToConversation()
                                setSwitchCampaignType(false)
                            }}
                        >
                            Yes, Switch
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateLink
