import RecorderButton from '../components/RecorderButton'
// import ToggleSwitch from '../components/ToggleSwitch'
import { useQuestionStore } from '../context/question/store'
import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { defaultBrandBgColor } from '../utils/constant'
import React, { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

const Profile = (): JSX.Element => {
    const navigate = useNavigate()
    const {
        fetchUser,
        state: { user },
    } = useUserStore()
    const {
        state: { question },
    } = useQuestionStore()

    // const [isEditConsent, setIsEditConsent] = useState<boolean>(false)
    // const [isNodeioBranding, setIsNodeioBranding] = useState<boolean>(false)

    // const [consentLink, setConsentLink] = useState<string>('')
    // const [termsLink, setTermsLink] = useState<string>('')
    // const [policyLink, setPolicyLink] = useState<string>('')

    // const toggleConsent = () => setIsEditConsent(!isEditConsent)

    useEffect(() => {
        fetchUser()
    }, [])

    // const disableBranding = async () => {
    //     setIsNodeioBranding(!isNodeioBranding)
    // }

    // const editConsent = () => {
    //     toggleConsent()
    // }

    // const saveConsent = () => {
    //     toggleConsent()
    // }

    return (
        <div className="flex-1 pb-0 xs:pb-[40px] px-4 xs:px-[40px] lg:pr-[120px]">
            <h2 className="text-[25px] font-bold text-[#8092A5]">My Brand</h2>
            <div className="flex justify-between mt-7">
                <p className="text-base text-black">Web recorder</p>
                <div className="flex items-center cursor-pointer select-none">
                    {/* <img src="/asset/color_lens.svg" alt="color-lens" /> */}
                    <a
                        className="text-base text-[#007AFF] font-bold pl-1"
                        onClick={() =>
                            navigate(
                                generatePath(Page.PROFILE_EDIT, {
                                    id: user.username,
                                }),
                            )
                        }
                    >
                        Edit
                    </a>
                </div>
            </div>
            <div className="relative flex flex-col h-[850px] items-center justify-between my-7 mt-5 rounded-xl bg-[#FFFFFF]">
                <div
                    className="relative flex flex-col w-full px-[25px] sm:px-[50px] pb-[120px] h-[200px] items-center rounded-t-lg"
                    style={{ background: user?.brand?.background_color || defaultBrandBgColor }}
                >
                    {user?.brand?.background_image_url && (
                        <img
                            src={user?.brand?.background_image_url}
                            className="w-full h-full absolute object-cover mx-[-25px] sm:mx-[-50px] mb-[-120px] hidden sm:block"
                        />
                    )}
                    {user?.brand?.background_mobile_image_url && (
                        <img
                            src={user?.brand?.background_mobile_image_url}
                            className="w-full h-full absolute object-cover mx-[-25px] sm:mx-[-50px] mb-[-120px] block sm:hidden"
                        />
                    )}
                </div>
                <div className="absolute top-[150px] px-[25px] w-full md:w-fit">
                    <div
                        className="w-full md:w-[580px] xl:w-[720px] px-[45px] py-10 pb-12 rounded-lg"
                        style={{
                            background:
                                'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                        }}
                    >
                        <React.Fragment>
                            <div className="flex flex-row gap-4 h-10">
                                <img
                                    src={
                                        user?.profile_photo?.url ??
                                        'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                    }
                                    width={40}
                                    height={40}
                                    className="w-10 h-10 rounded-full object-cover"
                                    alt="profile_photo"
                                />
                                <div className="flex flex-col justify-center">
                                    <p className="text-base text-[#BFDEFF]">
                                        {user?.first_name} {user?.last_name}
                                    </p>
                                    <p className="text-[13px] text-[#BFDEFF] ellipsis-1">
                                        {user?.bio}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-[26px]">
                                <p className="text-2xl text-white">{question}</p>
                            </div>
                        </React.Fragment>
                    </div>
                    <div className="flex flex-col items-center mt-[60px] pb-[40px]">
                        <RecorderButton
                            onStop={() => {}}
                            onRecorderNotSupported={() => {}}
                            disabled={true}
                            tooltip="Recorder is disabled in preview mode."
                        />
                    </div>
                    <div className="flex justify-center items-end flex-col gap-4 pb-[40px]">
                        <button
                            className={`text-[#BFDEFF] bg-[#007AFF] px-6 py-1 rounded-[10px] cursor-default`}
                        >
                            Submit
                        </button>
                        {/*{consentLink && (*/}
                        {/*    <p className="w-[350px] pr-0.5 text-sm">*/}
                        {/*        By recording your voice and clicking the submit button, you hereby*/}
                        {/*        confirm that you have read and consent to the Organization Name{' '}*/}
                        {/*        <a*/}
                        {/*            href={`//${consentLink}`}*/}
                        {/*            target="_blank"*/}
                        {/*            className="hover:underline text-[#007AFF]"*/}
                        {/*        >*/}
                        {/*            consent and publicity release*/}
                        {/*        </a>*/}
                        {/*        .*/}
                        {/*    </p>*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>
            {/*<div className="mt-4 mb-14 flex justify-end">*/}
            {/*    <ToggleSwitch*/}
            {/*        checked={isNodeioBranding}*/}
            {/*        label="Disable Nodeio branding"*/}
            {/*        onChange={disableBranding}*/}
            {/*        theme={{*/}
            {/*            root: {*/}
            {/*                label: 'text-[#B6D6FA] font-lato text-base ml-2',*/}
            {/*            },*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className="flex justify-between mt-24 mb-1">*/}
            {/*    <h3 className="text-white text-xl">Consent banner</h3>*/}
            {/*    <div className="flex items-center cursor-pointer select-none gap-3">*/}
            {/*        {!isEditConsent ? (*/}
            {/*            <a*/}
            {/*                className="text-base text-[#007AFF] font-bold pl-1"*/}
            {/*                onClick={editConsent}*/}
            {/*            >*/}
            {/*                Edit*/}
            {/*            </a>*/}
            {/*        ) : (*/}
            {/*            <>*/}
            {/*                <a*/}
            {/*                    className="text-base text-[#007AFF] font-bold pl-1 cursor-pointer select-none"*/}
            {/*                    onClick={toggleConsent}*/}
            {/*                >*/}
            {/*                    Cancel*/}
            {/*                </a>*/}
            {/*                <a*/}
            {/*                    className="text-base text-[#007AFF] font-bold pl-1 cursor-pointer select-none"*/}
            {/*                    onClick={saveConsent}*/}
            {/*                >*/}
            {/*                    Save*/}
            {/*                </a>*/}
            {/*            </>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<p className="text-[#B6D6FA] text-[15px]">*/}
            {/*    Customize the terms your respondents accept*/}
            {/*</p>*/}
            {/*{isEditConsent ? (*/}
            {/*    <>*/}
            {/*        <p className="text-[#B6D6FA] text-[15px] mt-2.5 mb-1">*/}
            {/*            Leave blank to omit. <b>Note</b>: While you own data collected, we recommend*/}
            {/*            asking for respondents consent to share publicly.{' '}*/}
            {/*        </p>*/}
            {/*        <div className="flex items-center gap-8 mt-5 mb-7">*/}
            {/*            <div className="flex-1">*/}
            {/*                <p className="text-white text-sm font-medium mb-2">*/}
            {/*                    Consent & Publicity Release*/}
            {/*                </p>*/}
            {/*                <div className="flex items-center rounded-lg border-1 border-[#D0D5DD]">*/}
            {/*                    <p className="px-3 py-2 border-r-[#D0D5DD] border-r-[1px] bg-white rounded-l-lg text-[#475467] text-base">*/}
            {/*                        http://*/}
            {/*                    </p>*/}
            {/*                    <input*/}
            {/*                        className="px-3 py-2 bg-white rounded-r-lg placeholder:text-[#667085] text-base text-black w-full outline-none focus-within:outline-none"*/}
            {/*                        placeholder="www.real-consent-link.com"*/}
            {/*                        value={consentLink}*/}
            {/*                        onChange={(e) => setConsentLink(e.target.value)}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-1">*/}
            {/*                <p className="text-white text-sm font-medium mb-2">Terms of Use</p>*/}
            {/*                <div className="flex items-center rounded-lg border-1 border-[#D0D5DD]">*/}
            {/*                    <p className="px-3 py-2 border-r-[#D0D5DD] border-r-[1px] bg-white rounded-l-lg text-[#475467] text-base">*/}
            {/*                        http://*/}
            {/*                    </p>*/}
            {/*                    <input*/}
            {/*                        className="px-3 py-2 bg-white rounded-r-lg placeholder:text-[#667085] text-base text-black w-full outline-none focus-within:outline-none"*/}
            {/*                        placeholder="www.nodeio.xyz/terms"*/}
            {/*                        value={termsLink}*/}
            {/*                        onChange={(e) => setTermsLink(e.target.value)}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="flex-1">*/}
            {/*                <p className="text-white text-sm font-medium mb-2">*/}
            {/*                    Privacy policy link*/}
            {/*                </p>*/}
            {/*                <div className="flex items-center rounded-lg border-1 border-[#D0D5DD]">*/}
            {/*                    <p className="px-3 py-2 border-r-[#D0D5DD] border-r-[1px] bg-white rounded-l-lg text-[#475467] text-base">*/}
            {/*                        http://*/}
            {/*                    </p>*/}
            {/*                    <input*/}
            {/*                        className="px-3 py-2 bg-white rounded-r-lg placeholder:text-[#667085] text-base text-black w-full outline-none focus-within:outline-none"*/}
            {/*                        placeholder="www.nodeio.xyz/privacy"*/}
            {/*                        value={policyLink}*/}
            {/*                        onChange={(e) => setPolicyLink(e.target.value)}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*) : (*/}
            {/*    <></>*/}
            {/*)}*/}
            {/*<div className="p-8 border-2 border-white bg-[#1F2C3B] rounded-xl flex items-center gap-8 mt-6 mb-14 justify-between">*/}
            {/*    <div className="text-[#BFDEFF]">*/}
            {/*        <p className="text-lg">Before Organization Name can hear your voice</p>*/}
            {/*        <p className="text-sm">*/}
            {/*            Please accept the{' '}*/}
            {/*            <a*/}
            {/*                href={`//${termsLink}`}*/}
            {/*                target="_blank"*/}
            {/*                className="hover:underline text-[#007AFF]"*/}
            {/*            >*/}
            {/*                terms of use*/}
            {/*            </a>{' '}*/}
            {/*            and{' '}*/}
            {/*            <a*/}
            {/*                href={`//${policyLink}`}*/}
            {/*                target="_blank"*/}
            {/*                className="hover:underline text-[#007AFF]"*/}
            {/*            >*/}
            {/*                privacy policy*/}
            {/*            </a>*/}
            {/*            .*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <div className="flex gap-4">*/}
            {/*        <button className="text-sm bg-[#007AFF] text-[#FFFFFF] font-bold px-12 py-2 rounded-lg cursor-default">*/}
            {/*            Accept*/}
            {/*        </button>*/}
            {/*        <button className="text-sm bg-[#FFFFFF] text-[#001F40DE] font-bold px-12 py-2 rounded-lg cursor-default">*/}
            {/*            Decline*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default Profile
