import { AuthenticationApi } from '../api/authentication'
import { UserApi } from '../api/user'
import Spinner from '../components/Spinner'
import { useUserStore } from '../context/user/store'
import { CookieKey } from '../enums/CookieKey'
import { Page } from '../enums/Page'
import { User } from '../types/User'
import { deleteCookie, setCookie } from '../utils/useCookies'
import { clarity } from 'clarity-js'
import moment from 'moment'
import { useState, useEffect, useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const _getColCount = (size: number) => {
    if (size > 770) return 3
    if (size > 400) return 2
    return 1
}

const Home = (): JSX.Element => {
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        document.body.style.overflowY = 'scroll'
        return () => {
            document.body.style.overflowY = 'hidden'
        }
    }, [])
    const CLARITY_PROJECT_ID = 'j8qkp85nmj'

    const [profiles, setProfiles] = useState<User[]>([])
    const [profile, setProfile] = useState<User | null>(null)
    const {
        state: { user, loading },
        setUser,
        setLoading,
    } = useUserStore()

    const [colCount, setColCount] = useState(_getColCount(window.innerWidth))
    const [viewAll, setViewAll] = useState<boolean>(true)

    const signIn = async (id: string) => {
        const response = await AuthenticationApi.selectProfile(id)
        if (response.status === 200) {
            setCookie(CookieKey.JWT_TOKEN, response.data.token, {
                expires: moment().add('30', 'minutes').toDate(),
            })
            setCookie(CookieKey.REFRESH_TOKEN, response.data.refresh_token, {
                expires: moment().add('1', 'day').toDate(),
            })
            const userResponse = await UserApi.getAuthenticatedUser()
            if (userResponse) {
                setUser(userResponse.data)
                navigate(Page.HOME)
            }
        }
    }

    useLayoutEffect(() => {
        const onResize = () => {
            setColCount(_getColCount(window.innerWidth))
        }
        window.addEventListener('resize', onResize)

        return () => window.removeEventListener('resize', onResize)
    }, [])

    useEffect(() => {
        ;(async function getProfile() {
            setLoading(true)
            try {
                const response = await AuthenticationApi.getProfiles()
                if (response.status === 200) {
                    setLoading(false)
                    if (state?.logged) {
                        setProfiles(response.data)
                        setProfile(response.data.find((p) => p.id === user.id) as User)
                    } else {
                        if (response.data.length === 1) {
                            signIn(response.data[0].id)
                        } else {
                            setProfiles(response.data)
                            if (response.data.length > 0) setProfile(response.data[0])
                        }
                    }
                }
            } catch (err) {
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            clarity.consent()
            if (user?.phone_number) {
                // The only way a found to pass specific info to clarity so i can filter info was using
                // cookies
                setCookie(CookieKey.USER_PHONE, user.phone_number)
                setCookie(CookieKey.USER_NAME, user.first_name + user.last_name)
                clarity.set(CookieKey.USER_PHONE, user.phone_number)
                clarity.set(CookieKey.USER_NAME, user.first_name + user.last_name)

                clarity.start({
                    projectId: CLARITY_PROJECT_ID,
                    upload: 'https://m.clarity.ms/collect',
                    track: true,
                    content: true,
                    cookies: [CookieKey.USER_PHONE, CookieKey.USER_NAME],
                })
            }

            return () => {
                clarity.stop()
            }
        }
    }, [user])

    const selectProfile = (id: string) => {
        setProfile(profiles.find((user) => user.id === id) as User)
    }

    return (
        <div className="flex mt-[150px] justify-center p-10">
            {loading && <Spinner />}
            <div>
                <h2 className="text-[28px] sm:text-[36px] text-[#007AFF] mx-1">
                    Select a profile to use today
                </h2>
                <div className="my-7 md:w-[630px] grid grid-cols-[repeat(auto-fit,_minmax(160px,_1fr))] sm:grid-cols-[repeat(auto-fit,_minmax(210px,_1fr))] content-start">
                    {profiles.slice(0, !viewAll ? undefined : colCount * 3).map((user: User) => (
                        <button
                            className="flex items-center gap-3 my-3 mx-3"
                            key={user.id}
                            onClick={() => selectProfile(user.id)}
                        >
                            <img
                                src={
                                    user?.profile_photo?.url ??
                                    'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                }
                                className={`w-[50px] sm:w-[68px] h-[50px] sm:h-[68px] rounded-full object-cover ${
                                    profile?.id === user.id ? 'border-4 border-[#007AFF]' : ''
                                }`}
                            />
                            <p className="text-base sm:text-xl text-[#001F40] font-bold text-left ellipsis-1">
                                {user.first_name}
                            </p>
                        </button>
                    ))}
                </div>
                {colCount * 3 < profiles.length && (
                    <button
                        className="text-[#001F40A1] font-bold text-base sm:text-xl flex items-center my-3 mx-3"
                        onClick={() => setViewAll((_) => !_)}
                    >
                        {viewAll ? 'View all' : 'View less'}
                        <svg
                            className="ml-2 sm:mt-1"
                            width="8"
                            height="15"
                            viewBox="0 0 9 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L6.58579 8L0.928932 13.6569C0.538408 14.0474 0.538408 14.6805 0.928932 15.0711C1.31946 15.4616 1.95262 15.4616 2.34315 15.0711L8.70711 8.70711ZM8 7H7V9H8V7Z"
                                fill="#5E7287"
                            />
                        </svg>
                    </button>
                )}
                <div className="flex items-center gap-8 mt-16">
                    <button
                        className={`px-5 py-3 rounded-[10px] font-bold text-base ${
                            profile ? 'text-[#BFDEFF] bg-[#007AFF]' : 'text-white bg-[#add4ff]'
                        }`}
                        onClick={() => profile && signIn(profile?.id)}
                    >
                        Sign in
                    </button>

                    <button
                        className="text-base text-[#0D0D0D]"
                        onClick={() => {
                            if (!user) {
                                deleteCookie(CookieKey.JWT_TOKEN)
                                deleteCookie(CookieKey.REFRESH_TOKEN)
                            }
                            navigate(-1)
                        }}
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Home
